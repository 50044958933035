import React, { useMemo, useState } from "react";
import { Typography, Button, Box, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DoneIcon from "@mui/icons-material/Done";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LinkA from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LaunchIcon from "@mui/icons-material/Launch";
import { Property } from "csstype";

import { approve, deleteContribution, Task, Token } from "../FirebaseAction";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useAvatarUrl } from "presentation/utils/useAvatar";
import { useUsername } from "presentation/utils/useUsername";

// TODO: [key: string]: anyをちゃんと型定義する
type props = {
  maxWidth?: Property.MaxWidth;
  minify?: boolean;
  canApproval?: boolean;
  relatedTask?: Task;
  token?: Token;
  [key: string]: any;
};

export const ContributionCard = ({
  minify = false,
  maxWidth = "600",
  canApproval = false,
  relatedTask,
  ...props
}: props) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [token, setToken] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const avatarUrl = useAvatarUrl(props.contribution.user.id);
  const username = useUsername(props.contribution.user.id);

  const menuOpen = Boolean(anchorEl);
  const setMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setToken(event.target.value);
  };

  const [content, setContent] = useState({
    id: "id",
    name: "name",
    content: "content",
    user: {
      name: "name",
    },
  });

  const ApproveDialogue = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="contribution" />
          <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼Content
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={async () => {
              handleClose();
              try {
                const res = relatedTask
                  ? await approve(
                      "contributionToTask",
                      content.id,
                      relatedTask.teamid,
                      relatedTask.createrid,
                      props.contribution.user.id,
                      relatedTask.reward
                    )
                  : await approve("contributions", content.id);
                props.setSuccess && props.setSuccess(res);
                toast.success("Verified the Task!");
              } catch (error) {
                if (error instanceof Error) {
                  toast.error(error.message);
                } else {
                  toast.error("An unexpected error occurred...");
                }
              }
              navigate(`${location.pathname}${location.search}`, {
                state: {
                  shouldLoadContributions: true,
                },
              });
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const DeleteDialogue = () => {
    return (
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="delete" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼Content
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={async () => {
              setDeleteOpen(false);
              const result = await deleteContribution(
                props.userid,
                props.contribution.id
              );
              props.setSuccess(result);
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function autoLink(str) {
    let result = false;
    if (str.includes("http")) {
      result = true;
    }
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const eliminateNum = /<@[0-9]+>/;
    if (str.length >= 70) {
      return [
        result,
        str.match(regexp_url),
        str.slice(0, 70).replace(regexp_url, "").replace(eliminateNum, "") +
          "...",
      ];
    } else {
      return [
        result,
        str.match(regexp_url),
        str.replace(regexp_url, "").replace(eliminateNum, ""),
      ];
    }
  }

  return (
    <div>
      <Card
        sx={{ maxWidth, height: minify ? null : 280, justifyContent: "center" }}
      >
        <ApproveDialogue></ApproveDialogue>
        <DeleteDialogue></DeleteDialogue>
        <CardHeader
          align="center"
          avatar={
            props.contribution.twitter !== undefined ? (
              <Avatar src={props.contribution.user.avatarid}></Avatar>
            ) : (
              <Avatar src={avatarUrl}></Avatar>
            )
          }
          style={minify ? { paddingBottom: 0 } : {}}
          action={
            <div>
              <IconButton aria-label="settings" onClick={setMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={setMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                  component={Link}
                  to={`/profile/${props.contribution.user.id}`}
                >
                  View Profile
                </MenuItem>
                {props.contribution.twitter !== undefined ? (
                  <LinkA
                    href={`https://twitter.com/${props.contribution.user.username}/status/${props.contribution.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <MenuItem onClick={setMenuClose}>View Content</MenuItem>
                  </LinkA>
                ) : props.contribution.channelid !== undefined ? (
                  <LinkA
                    href={`https://discord.com/channels/${props.contribution.teamid}/${props.contribution.channelid}/${props.contribution.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <MenuItem onClick={setMenuClose}>View Content</MenuItem>
                  </LinkA>
                ) : (
                  <Box></Box>
                )}
                {props.userid === props.contribution.user.id && (
                  <MenuItem
                    onClick={async () => {
                      handleClose();
                      setContent(props.contribution);
                      setDeleteOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          }
          title={username}
          subheader={
            new Date(props.contribution.createdat).getFullYear().toString() +
            "/" +
            (new Date(props.contribution.createdat).getMonth() + 1).toString() +
            "/" +
            new Date(props.contribution.createdat).getDate().toString()
          }
        />
        {
          <CardActions>
            {props.contribution.approved === "approved" ? (
              <Button
                sx={{
                  textTransform: "none",
                  maxWidth: "120px",
                  minWidth: "120px",
                  color: "#00ff7f",
                }}
              >
                <DoneIcon></DoneIcon>
                <Typography>Verified</Typography>
              </Button>
            ) : (
              <Button
                variant="outlined"
                disabled={!canApproval}
                sx={{
                  textTransform: "none",
                  maxWidth: "64px",
                  minWidth: "120px",
                  color: "#a9a9a9",
                }}
                onClick={() => {
                  if (canApproval) {
                    console.log("called");
                    setContent(props.contribution);
                    handleClickOpen();
                  } else {
                    setContent(props.contribution);
                    // test用。解除しておく
                    // handleClickOpen();
                  }
                }}
              >
                <Typography>Verify</Typography>
              </Button>
            )}
            <div style={{ flexGrow: 1 }}></div>
            {relatedTask ? (
              props.token && (
                <Button>
                  <Avatar style={{ marginRight: 5 }} src={props.token.avatar} />
                  {relatedTask.reward} {props.token.name}
                </Button>
              )
            ) : (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Token</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={token}
                  label="Token"
                  onChange={handleChange}
                >
                  <MenuItem value={"0"}>0</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"20"}>20</MenuItem>
                  <MenuItem value={"30"}>30</MenuItem>
                  <MenuItem value={"40"}>40</MenuItem>
                  <MenuItem value={"50"}>50</MenuItem>
                  <MenuItem value={"60"}>60</MenuItem>
                  <MenuItem value={"70"}>70</MenuItem>
                  <MenuItem value={"80"}>80</MenuItem>
                  <MenuItem value={"90"}>90</MenuItem>
                  <MenuItem value={"100"}>100</MenuItem>
                </Select>
              </FormControl>
            )}
          </CardActions>
        }

        {/* ここに、タグがある場合に該当するものを表示 */}
        {props.contribution.type !== undefined && (
          <CardActions>
            <Typography sx={{ ml: 3, color: "Gray" }}>
              #{props.tags && props.tags[props.contribution.type]}
            </Typography>
          </CardActions>
        )}
        <CardContent sx={minify ? { height: 36 } : {}}>
          {autoLink(props.contribution.content)[0] ? (
            <Typography>
              {autoLink(props.contribution.content)[2]}
              <LinkA
                href={autoLink(props.contribution.content)[1]}
                underline="none"
                target="_blank"
              >
                (<FormattedMessage id="link" />)
              </LinkA>
            </Typography>
          ) : (
            <Typography>{autoLink(props.contribution.content)}</Typography>
          )}
        </CardContent>
        {props.contribution.twitter !== undefined && !minify ? (
          <CardActions>
            <Button
              sx={{ ml: 3, marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                window.open(
                  `https://twitter.com/${props.contribution.user.username}/status/${props.contribution.id}`,
                  "_blank"
                );
              }}
            >
              <Typography sx={{ ml: 3, color: "Gray" }}>
                View on Twitter
              </Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          </CardActions>
        ) : props.contribution.channelid !== undefined && !minify ? (
          <CardActions>
            <Button
              sx={{ ml: 3, mb: 0.1, marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                window.open(
                  `https://discord.com/channels/${props.contribution.teamid}/${props.contribution.channelid}/${props.contribution.id}`,
                  "_blank"
                );
              }}
            >
              <Typography sx={{ ml: 3, color: "Gray" }}>
                View on Discord
              </Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          </CardActions>
        ) : (
          <Box></Box>
        )}
      </Card>
    </div>
  );
};
