// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFirebaseConfig } from "./FirebaseConfig.ts";
// import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = getFirebaseConfig(process.env.NODE_ENV);
console.log(process.env.NODE_ENV);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const app2 = initializeApp(firebaseConfig2);
// const auth = getAuth();
// onAuthStateChanged(auth, user => { console.log(user); });
export const firebaseFirestore = getFirestore(app);
// export const firebaseFirestore2 = getFirestore(app2);
// export const providerTwitter = new app.auth.TwitterAuthProvider();
// export const fireauth = getAuth();
