import React, { useState, useEffect, useContext } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import ConvertUtils from "../../utils/ConvertUtils";

interface FilterSelect {
  date: string | null;
  setDate: (value: string) => void;
}

const FilterBtnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin: 24px 5%;
`;
const SelectPeriod = styled(Select)`
  min-width: 120px;
`;

const FilterSelect: React.FC<FilterSelect> = ({ date, setDate }) => {
  const [months, setMonths] = useState([
    ConvertUtils.generateSpecificYyyymm(0),
  ]);

  useEffect(() => {
    const tmp: any = [];
    for (let i = 0; i < 12; i++) {
      const option = ConvertUtils.generateSpecificYyyymm(i);
      const optionDate = new Date(option + "-1");
      const genesisDate = new Date("2022-11-1");
      if (optionDate.getTime() > genesisDate.getTime()) tmp.push(option);
    }
    tmp.unshift("ALL");
    setMonths(tmp);
  }, []);

  return (
    <FilterBtnWrapper>
      <FormControl>
        <InputLabel id="periodLabel">Period</InputLabel>
        <SelectPeriod
          labelId="periodLabel"
          id="periodLabel"
          value={date}
          label="Period"
          onChange={(e: any) => setDate(e.target.value as string)}
        >
          {months.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </SelectPeriod>
      </FormControl>
    </FilterBtnWrapper>
  );
};

export default FilterSelect;
