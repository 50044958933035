import React, { useState, useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../../App";

import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Logout } from "../../../data/blockchain_actions/login";
import Grid from "@mui/material/Grid";
import "./Header.css";

// リンクへのアクセス
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  mode: "dark",
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const setATfunc = useContext(AuthContext);
  const classes = useStyles();
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [, setSelected] = useState("proposals");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [admin] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // アイコン画像保存用
  const [iconImg, setIconImg] = useState("");

  useEffect(() => {
    // CheckWallet();
    check();
    if (setATfunc.tokenState !== null) {
      setIconImg(
        `https://cdn.discordapp.com/avatars/${setATfunc.tokenState.id}/${setATfunc.tokenState.avatar}`
      );
      setCurrentAccount(setATfunc.tokenState.username);
      setCurrentUserName(setATfunc.tokenState.id);
    }
  }, [setATfunc]);

  const check = () => {};

  const checkURL = useCallback(async () => {
    // URLを取得し、該当するものにセット
    if (window.location.href.indexOf("/proposal") > 0) {
      setSelected("proposals");
    } else if (window.location.href.indexOf("/team") > 0) {
      setSelected("teams");
    } else if (window.location.href.indexOf("/profile") > 0) {
      setSelected("mypage");
    } else if (window.location.href.indexOf("/task") > 0) {
      setSelected("tasks");
    } else if (window.location.href.indexOf("/dashboard") > 0) {
      setSelected("dashboard");
    }

    // TODO: useLocationで代替する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    checkURL();
  }, [checkURL]);

  return (
    <div className={classes.toolbarWrapper}>
      <AppBar position="static">
        <Toolbar className="header" sx={{ zIndex: 999 }}>
          <Link to="/">
            <Box
              component="img"
              justifyContent="left"
              sx={{
                height: 64,
                display: { xs: "none", md: "flex" },
              }}
              alt="Your logo."
              src={`../../../../icon_wide.png`}
            ></Box>
          </Link>
          <Link to="/">
            <Box
              component="img"
              justifyContent="left"
              sx={{
                height: 64,
                display: { xs: "flex", md: "none" },
                ml: 4,
              }}
              alt="Your logo."
              src={`../../../../icon.png`}
            ></Box>
          </Link>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ display: { xs: "flex", md: "flex" } }}
          >
            {setATfunc.tokenState !== null ? (
              <div>
                {/* <Typography>
                  <Avatar alt="Remy Sharp" src="https://pbs.twimg.com/profile_images/1532011932688846848/vnzRGVAO_normal.jpg" />
                </Typography> */}
                <Box
                  style={{ minWidth: "135px", textDecoration: "none" }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ mr: 2, ml: 2 }}
                      style={{ textDecoration: "none" }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={iconImg}
                      ></Avatar>
                      {currentAccount}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: 10,
                        mr: 10,
                        pr: 10,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem>
                    <Avatar /> Profile
                  </MenuItem>
                  <MenuItem>
                    <Avatar /> My account
                  </MenuItem> */}
                  {admin && (
                    <MenuItem
                      component={Link}
                      to="/admin"
                      onClick={handleDrawerToggle}
                    >
                      <ListItemIcon>
                        <AdminPanelSettingsIcon fontSize="small" />
                      </ListItemIcon>
                      管理画面へ
                    </MenuItem>
                  )}
                  <Divider />
                  <MenuItem
                    component={Link}
                    to={`/profile/${currentUserName}`}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    マイページ
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      Logout();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                {/* <Button style={{ minWidth: '135px' }} color="inherit" onClick={() => { Logout() }}>
                  Logout
                </Button> */}
              </div>
            ) : (
              <Button
                style={{ minWidth: "135px" }}
                color="inherit"
                onClick={() => {
                  if (process.env.NODE_ENV === "production") {
                    window.location.href =
                      "https://discord.com/oauth2/authorize?response_type=token&client_id=1046618452661641226&scope=identify";
                  } else {
                    window.location.href =
                      "https://discord.com/oauth2/authorize?response_type=token&client_id=1075783094461792286&scope=identify";
                  }

                  // Login().then(function (result) {
                  //   const img = result;
                  //   console.log(result);
                  //   setIconImg(result)
                  //   if (result.match(/ツボツボ/)) {
                  //     setAdmin(true)
                  //   }
                  // });
                }}
              >
                Login
              </Button>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
