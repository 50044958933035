import React, { useEffect, useContext, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TaskIcon from "@mui/icons-material/Task";
import AssistantIcon from "@mui/icons-material/Assistant";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SettingsIcon from "@mui/icons-material/Settings";

import Grid from "@mui/material/Grid";
import "./Header.css";
import { AuthContext } from "../../../App";
import { checkAdmin } from "../FirebaseAction";

// リンクへのアクセス
import { Link } from "react-router-dom";

export default function Buttons(props) {
  const guildId = props.guildId;
  const [selected, setSelected] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };

  const setATfunc = useContext(AuthContext);
  const [admin, setAdmin] = React.useState(false);
  const [, setUserid] = React.useState(null);

  const checkURL = useCallback(async () => {
    // URLを取得し、該当するものにセット
    if (window.location.href.indexOf("/proposal") > 0) {
      setSelected("proposals");
    } else if (window.location.href.indexOf("/contributions") > 0) {
      setSelected("contributions");
    } else if (window.location.href.indexOf("/thanks") > 0) {
      setSelected("thanks");
    } else if (window.location.href.indexOf("/dashboard") > 0) {
      setSelected("dashboard");
    } else if (window.location.href.indexOf("/settings") > 0) {
      setSelected("settings");
    } else if (window.location.href.indexOf("/tasks") > 0) {
      setSelected("tasks");
    }
    // TODO: useLocationを使う
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    checkURL();
    (async () => {
      if (setATfunc.tokenState !== null) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(guildId, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
    })();
  }, [checkURL, guildId, setATfunc.tokenState]);

  return (
    <div>
      <AppBar position="static">
        <Toolbar className="header">
          {/* <Box
            component="img"
            justifyContent="left"
            alt="Your logo."
            src={`../../../../icon_wide.png`}
          >
          </Box> */}
          <br />
          <Grid
            container
            justifyContent="center"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {selected === "contributions" ? (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{
                  minWidth: "135px",
                  background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                }}
                color="inherit"
                component={Link}
                to={`/contributions/${guildId}`}
                onClick={() => {
                  setSelected("contributions");
                }}
              >
                <Diversity2Icon sx={{ m: 0.5 }}></Diversity2Icon>
                Contributions
              </Button>
            ) : (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{ minWidth: "135px" }}
                color="inherit"
                component={Link}
                to={`/contributions/${guildId}`}
                onClick={() => {
                  setSelected("contributions");
                }}
              >
                <Diversity2Icon sx={{ m: 0.5 }}></Diversity2Icon>
                Contributions
              </Button>
            )}
            {selected === "proposals" ? (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{
                  minWidth: "135px",
                  background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                }}
                color="inherit"
                component={Link}
                to={`/proposals/${guildId}`}
                onClick={() => {
                  setSelected("proposals");
                }}
              >
                <AssistantIcon sx={{ m: 0.5 }}></AssistantIcon>
                Proposals
              </Button>
            ) : (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{ minWidth: "135px" }}
                color="inherit"
                component={Link}
                to={`/proposals/${guildId}`}
                onClick={() => {
                  setSelected("proposals");
                }}
              >
                <AssistantIcon sx={{ m: 0.5 }}></AssistantIcon>
                Proposals
              </Button>
            )}
            {selected === "thanks" ? (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{
                  minWidth: "135px",
                  background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                }}
                color="inherit"
                component={Link}
                to={`/thanks/${guildId}`}
                onClick={() => {
                  setSelected("thanks");
                }}
              >
                <VolunteerActivismIcon sx={{ m: 0.5 }}></VolunteerActivismIcon>
                Thanks
              </Button>
            ) : (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{ minWidth: "135px" }}
                color="inherit"
                component={Link}
                to={`/thanks/${guildId}`}
                onClick={() => {
                  setSelected("thanks");
                }}
              >
                <VolunteerActivismIcon sx={{ m: 0.5 }}></VolunteerActivismIcon>
                Thanks
              </Button>
            )}
            {selected === "tasks" ? (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{
                  minWidth: "135px",
                  background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                }}
                color="inherit"
                component={Link}
                to={`/tasks/${guildId}`}
                onClick={() => {
                  setSelected("tasks");
                }}
              >
                <TaskIcon sx={{ m: 0.5 }}></TaskIcon>
                Tasks
              </Button>
            ) : (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{ minWidth: "135px" }}
                color="inherit"
                component={Link}
                to={`/tasks/${guildId}`}
                onClick={() => {
                  setSelected("tasks");
                }}
              >
                <TaskIcon sx={{ m: 0.5 }}></TaskIcon>
                Tasks
              </Button>
            )}
            {selected === "dashboard" ? (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{
                  minWidth: "135px",
                  background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                }}
                color="inherit"
                component={Link}
                to={`/dashboard/${guildId}`}
                onClick={() => {
                  setSelected("dashboard");
                }}
              >
                <DashboardIcon sx={{ m: 0.5 }}></DashboardIcon>
                dashboard
              </Button>
            ) : (
              <Button
                sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                style={{ minWidth: "135px" }}
                color="inherit"
                component={Link}
                to={`/dashboard/${guildId}`}
                onClick={() => {
                  setSelected("dashboard");
                }}
              >
                <DashboardIcon sx={{ m: 0.5 }}></DashboardIcon>
                dashboard
              </Button>
            )}
            {admin &&
              (selected === "settings" ? (
                <Button
                  sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                  style={{
                    minWidth: "135px",
                    background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                  }}
                  color="inherit"
                  component={Link}
                  to={`/settings/${guildId}`}
                  onClick={() => {
                    setSelected("settings");
                  }}
                >
                  <SettingsIcon sx={{ m: 0.5 }}></SettingsIcon>
                  settings
                </Button>
              ) : (
                <Button
                  sx={{ ml: 2, mr: 2, pl: 2, pr: 2 }}
                  style={{ minWidth: "135px" }}
                  color="inherit"
                  component={Link}
                  to={`/settings/${guildId}`}
                  onClick={() => {
                    setSelected("settings");
                  }}
                >
                  <SettingsIcon sx={{ m: 0.5 }}></SettingsIcon>
                  settings
                </Button>
              ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
          >
            <List>
              <ListItem>
                <HomeIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to="/"
                  onClick={handleDrawerToggle}
                >
                  Home
                </Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to={`/contributions/${guildId}`}
                  onClick={handleDrawerToggle}
                >
                  Contributions
                </Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to={`/proposals/${guildId}`}
                  onClick={handleDrawerToggle}
                >
                  Proposals
                </Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to={`/tasks/${guildId}`}
                  onClick={handleDrawerToggle}
                >
                  Tasks
                </Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to={`/thanks/${guildId}`}
                  onClick={handleDrawerToggle}
                >
                  Thanks
                </Button>
              </ListItem>
              <ListItem>
                <InfoIcon />
                <Button
                  color="inherit"
                  component={Link}
                  to={`/dashboard/${guildId}`}
                  onClick={handleDrawerToggle}
                >
                  dashboard
                </Button>
              </ListItem>
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
}
