import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  FormGroup,
  Paper,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Menu,
  CardActions,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";

import {
  checkAdmin,
  checkAdminId,
  storeSetting,
  checkTeam,
  checkAllAction,
  registerAdmin,
} from "../FirebaseAction";
import { AuthContext } from "../../../App";
import Buttons from "../components/Buttons";
import CircularProgress from "@mui/material/CircularProgress";

import { OutputAlert } from "../SnackBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const PrewardWrapper = styled(Grid)`
  width: 100vw;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
  }
`;

const SideBar = styled(Grid)``;

const MainArea = styled(Grid)`
  width: inherit;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: 24px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 0;
  }
`;

const SectionWrapper = styled(Box)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    margin-bottom: 32px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled(Typography)`
  color: white;
`;

const Settings = () => {
  const { teamid } = useParams();
  const [success, setSuccess] = React.useState<any>("false");

  // userのidを取得、チームのadminであるか確認
  const setATfunc = useContext(AuthContext) as any;
  const [, setAdmin] = React.useState(false);
  const [adminId, setAdminId] = React.useState<any[]>([]);
  const [, setUserid] = React.useState(null);

  const [user, setUser] = useState({
    name: "",
    id: "",
  });

  const [defaultReward, setDefaultReward] = React.useState({
    token: "UNYT",
    proposal: 10,
    contribution: 10,
    thanks: 10,
    approvalthreshold: 5,
  });

  const [reward, setReward] = React.useState({
    token: defaultReward.token,
    proposal: defaultReward.proposal,
    contribution: defaultReward.contribution,
    thanks: defaultReward.thanks,
    approvalthreshold: defaultReward.approvalthreshold,
  });

  useEffect(() => {
    (async () => {
      if (!teamid) {
        return;
      }
      if (setATfunc.tokenState !== null) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
      const adminArr = await checkAdminId(teamid);
      setAdminId(adminArr);
      const defaultState = await checkTeam(teamid);
      if (defaultState) {
        setDefaultReward({
          token: defaultState.token,
          proposal: defaultState.reward.proposal,
          contribution: defaultState.reward.contribution,
          thanks: defaultState.reward.thanks,
          approvalthreshold: defaultState.approvalthreshold,
        });
        setReward({
          token: defaultState.token,
          proposal: defaultState.reward.proposal,
          contribution: defaultState.reward.contribution,
          thanks: defaultState.reward.thanks,
          approvalthreshold: defaultState.approvalthreshold,
        });
      }
      const [contributions] = await checkAllAction(teamid);
      setContribution(contributions);
    })();
  }, [setATfunc.tokenState, success, teamid]);

  const [selected, setSelected] = React.useState("Tokens");

  const handleChange = (event) => {
    setReward((prevState) => ({ ...prevState, token: event.target.value }));
  };
  const handleChange1 = (event) => {
    setReward((prevState) => ({ ...prevState, proposal: event.target.value }));
  };
  const handleChange2 = (event) => {
    setReward((prevState) => ({
      ...prevState,
      contribution: event.target.value,
    }));
  };
  const handleChange3 = (event) => {
    setReward((prevState) => ({ ...prevState, thanks: event.target.value }));
  };

  const [inputError, setInputError] = useState(false);

  const handleChange4 = (event) => {
    // approvalthresholdが自然数かをチェック
    if (!event.target.value.match(/^[1-9][0-9]*$/)) {
      setInputError(true);
      return;
    }
    setInputError(false);
    setReward((prevState) => ({
      ...prevState,
      approvalthreshold: event.target.value,
    }));
  };
  const useDelay = (msec) => {
    const [waiting, setWaiting] = useState(true);
    useEffect(() => {
      setTimeout(() => setWaiting(false), msec);
    }, [msec]);
    return waiting;
  };
  const waiting = useDelay(1000);

  const [snack, setSnack] = React.useState("false");

  const [contribution, setContribution] = React.useState<any[]>([]);

  const MemberCard = () => {
    // 削除ボタン用メニュー
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const setMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const setMenuClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={2}>
            {contribution.map((eachCont) => {
              let adminmember = false;
              if (adminId.includes(eachCont.user.id)) {
                adminmember = true;
              }
              return (
                <Grid item xs={12} md={4}>
                  <AdminDialogue></AdminDialogue>
                  <Card sx={{ maxWidth: 600, height: 240 }}>
                    <CardHeader
                      align="center"
                      avatar={
                        <Avatar
                          src={`https://cdn.discordapp.com/avatars/${eachCont.user.id}/${eachCont.user.avatar}`}
                        ></Avatar>
                      }
                      action={
                        <div>
                          <IconButton
                            aria-label="settings"
                            onClick={setMenuOpen}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={setMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={setMenuClose}
                              component={Link}
                              to={`/profile/${eachCont.user.id}`}
                            >
                              View Profile
                            </MenuItem>
                            {/* {props.userid==eachCont.user.id && (<MenuItem onClick={setMenuClose}>Delete</MenuItem>)} */}
                          </Menu>
                        </div>
                      }
                      title={eachCont.user.name}
                    />
                    <CardActions>
                      {adminmember && (
                        <Button
                          sx={{
                            minWidth: "135px",
                            background:
                              "linear-gradient(45deg, #ff7f50,#ff1493)",
                            color: "white",
                            m: 1,
                            mb: 3,
                          }}
                        >
                          <Typography>Admin</Typography>
                        </Button>
                      )}
                      {!adminmember && (
                        <Button
                          variant="outlined"
                          sx={{
                            minWidth: "135px",
                            color: "white",
                            m: 1,
                            mb: 3,
                          }}
                          onClick={() => {
                            handleClickOpen();
                            setUser(eachCont.user);
                          }}
                        >
                          <Typography>Member</Typography>
                        </Button>
                      )}
                    </CardActions>
                    <CardContent>
                      <Typography>
                        value:
                        {eachCont.activity.total * 30}UNYT
                        <br />
                        get:
                        {0}UNYT
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AdminDialogue = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="admin" />
          <br />
          {user.name}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="no" />
            <br />
          </Button>
          <Button
            onClick={() => {
              handleClose();
              registerAdmin(teamid, user.id).then((res) => {
                setSuccess(res);
                setSelected("Tokens");
              });
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      <PrewardWrapper container spacing={0}>
        <SideBar xs={12} md={3}>
          <FormGroup>
            <SectionTitle variant="h5" gutterBottom>
              CATEGORY
            </SectionTitle>
            <Button
              variant={selected === "Tokens" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "Tokens"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("Tokens");
              }}
            >
              Tokens
              <br />
            </Button>
            <Button
              variant={selected === "Commands" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "Commands"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("Commands");
              }}
            >
              Commands
              <br />
            </Button>
            <Button
              variant={selected === "Admins" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "Admins"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("Admins");
              }}
            >
              Admins
              <br />
            </Button>
          </FormGroup>
          {/* <Divider orientation="vertical" /> */}
        </SideBar>

        <MainArea xs={12} md={9}>
          <SectionWrapper>
            <Grid container item spacing={3}>
              <React.Fragment>
                <div className="loading-container">
                  {waiting && (
                    <CircularProgress
                      sx={{ mt: 12, mb: 12 }}
                    ></CircularProgress>
                  )}
                </div>
                <OutputAlert snack={snack} setSnack={setSnack}></OutputAlert>
                {!waiting && selected === "Tokens" && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" className="white">
                      Token type
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
                      <InputLabel id="demo-simple-select-label">
                        Token
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reward.token}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={"UNYT"}>$UNYT</MenuItem>
                        <MenuItem value={"ポテト"}>🍟ポテト</MenuItem>
                        <MenuItem value={"MED"}>$MED</MenuItem>
                        <MenuItem value={"USDC"}>$USDC</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography variant="h5" className="white">
                      Token amount (per 1 Action)
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
                      <InputLabel id="demo-simple-select-label">
                        Proposal
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reward.proposal}
                        label="Age"
                        onChange={handleChange1}
                      >
                        <MenuItem value={10}>10{reward.token}</MenuItem>
                        <MenuItem value={20}>20{reward.token}</MenuItem>
                        <MenuItem value={30}>30{reward.token}</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
                      <InputLabel id="demo-simple-select-label">
                        Contribution
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reward.contribution}
                        label="Age"
                        onChange={handleChange2}
                      >
                        <MenuItem value={10}>10{reward.token}</MenuItem>
                        <MenuItem value={20}>20{reward.token}</MenuItem>
                        <MenuItem value={30}>30{reward.token}</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
                      <InputLabel id="demo-simple-select-label">
                        Thanks(Take)
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reward.thanks}
                        label="Age"
                        onChange={handleChange3}
                      >
                        <MenuItem value={10}>10{reward.token}</MenuItem>
                        <MenuItem value={20}>20{reward.token}</MenuItem>
                        <MenuItem value={30}>30{reward.token}</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography variant="h5" className="white">
                      Approval threshold
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ mt: 5, mb: 5 }}
                      error={inputError}
                      helperText={
                        inputError ? "Please enter a natural number." : null
                      }
                      id="outlined-number"
                      label="Threshold"
                      inputProps={{
                        min: 1,
                      }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={reward.approvalthreshold}
                      onChange={handleChange4}
                    />
                    <Button
                      sx={{
                        width: "150px",
                        background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                        color: "white",
                        mt: 2,
                      }}
                      onClick={() => {
                        storeSetting(teamid, reward).then(() => {
                          setSuccess(Date.now());
                          setSnack("setting");
                        });
                      }}
                    >
                      SAVE
                      <br />
                    </Button>
                  </Grid>
                )}
                {!waiting && selected === "Admins" && (
                  <Grid item xs={12} md={12}>
                    <MemberCard></MemberCard>
                  </Grid>
                )}
              </React.Fragment>
            </Grid>
          </SectionWrapper>
        </MainArea>
      </PrewardWrapper>
    </div>
  );
};

export default Settings;
