import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grow,
  Paper,
  Slide,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  newCheckTasks,
  checkAdmin,
  Task,
  checkTeam,
  Token,
  checkToken,
} from "../../FirebaseAction";
import { AuthContext } from "../../../../App";
import { TaskCard } from "../../components/taskCard";
import Buttons from "../../components/Buttons";
import TaskContributersTabel from "../TaskContributersTable";
import FilterSelect from "../../components/FilterSelect";
import ConvertUtils from "../../../utils/ConvertUtils";
import { useServerProfilesContext } from "presentation/view_interfaces/components/ServerProfileProvider";

const PageWrapper = styled(Grid)`
  width: 100vw;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
`;

const SideBar = styled(Grid)``;

const MainArea = styled(Grid)`
  width: inherit;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 0;
  }
`;

const SectionWrapper = styled(Box)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    margin-bottom: 32px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled(Typography)`
  color: white;
`;

const TaskIndexPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchParams] = useSearchParams();
  const taskId = useMemo(() => {
    return searchParams.get("task");
  }, [searchParams]);
  const isTaskSelected = useMemo(() => {
    return taskId !== null;
  }, [taskId]);
  const tableContainerRef = useRef(null);

  const { teamid = "" } = useParams();
  const [isCompleted, setIsCompleted] = useState(true);
  const [isWIP, setIsWIP] = useState(true);
  const [success, setSuccess] = React.useState("false");

  // userのidを取得、チームのadminであるか確認
  const setATfunc = useContext(AuthContext) as any;
  const [admin, setAdmin] = React.useState(false);
  const [userid, setUserid] = React.useState(null);

  const [token, setToken] = useState<Token>();

  const [tasks, setTasks] = useState<Task[]>([]);

  const { setTeam } = useServerProfilesContext();

  // date params
  const [date, setDate] = React.useState<string | null>(
    ConvertUtils.generateSpecificYyyymm(0)
  );

  useEffect(() => {
    (async () => {
      const resp = await newCheckTasks(teamid, date);
      setTasks(resp);
      if (setATfunc.tokenState !== null && teamid) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
    })();
  }, [setATfunc.tokenState, success, teamid, date]);

  useEffect(() => {
    (async () => {
      setTeam(teamid);
      const t = await checkTeam(teamid);
      const resp = t && (await checkToken(t.token));
      resp && setToken(resp);
    })();
  }, [setTeam, teamid]);

  const displayingTasks = useMemo(() => {
    const conditions: ("wip" | "done")[] = [];
    isWIP && conditions.push("wip");
    isCompleted && conditions.push("done");
    return tasks.filter((task) => conditions.includes(task.status));
  }, [isCompleted, isWIP, tasks]);

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      <Box display="flex" flexDirection="column">
        <FilterSelect date={date} setDate={setDate} />
        <Box display="flex" justifyContent="center">
          <PageWrapper container spacing={0}>
            {isMobile && isTaskSelected && (
              <MainArea item md={4.5} ref={tableContainerRef}>
                <Slide
                  direction="left"
                  in={isTaskSelected && taskId !== null}
                  mountOnEnter
                  unmountOnExit
                  container={tableContainerRef.current}
                >
                  <TaskContributersTabel
                    teamid={teamid}
                    taskid={taskId || ""}
                    token={token}
                  />
                </Slide>
              </MainArea>
            )}

            <SideBar item xs={12} md={3}>
              <FormGroup>
                <SectionTitle variant="h5" gutterBottom>
                  CATEGORY
                </SectionTitle>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isWIP}
                      onChange={() => setIsWIP(!isWIP)}
                    />
                  }
                  label="進行中"
                  sx={{ color: "#ff7f50" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={isCompleted}
                      onChange={() => setIsCompleted(!isCompleted)}
                    />
                  }
                  label="完了済み"
                  sx={{ color: "#66bb6a" }}
                />
              </FormGroup>
            </SideBar>

            <MainArea item xs={12} md={isTaskSelected ? 4.5 : 9}>
              <SectionWrapper>
                <Grid container item spacing={3}>
                  {displayingTasks.map((eachTask) => {
                    return (
                      <Grow
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        timeout={300}
                        key={eachTask.id}
                      >
                        <Grid item xs={12} md={isTaskSelected ? 12 : 4}>
                          <TaskCard
                            shouldHighlight={taskId === eachTask.id}
                            task={eachTask}
                            setSuccess={setSuccess}
                            admin={admin}
                            userid={userid}
                            token={token}
                          ></TaskCard>
                        </Grid>
                      </Grow>
                    );
                  })}
                </Grid>
              </SectionWrapper>
            </MainArea>
            {!isMobile && isTaskSelected && (
              <MainArea item md={4.5} ref={tableContainerRef}>
                <Slide
                  direction="left"
                  in={isTaskSelected && taskId !== null}
                  mountOnEnter
                  unmountOnExit
                  container={tableContainerRef.current}
                >
                  <TaskContributersTabel
                    teamid={teamid}
                    taskid={taskId || ""}
                    token={token}
                  />
                </Slide>
              </MainArea>
            )}
          </PageWrapper>
        </Box>
      </Box>
    </div>
  );
};

export default TaskIndexPage;
