import React, { useEffect, useMemo, useState } from "react";
import { Typography, Button, Box, IconButton, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import LinkA from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";

import { FormattedMessage } from "react-intl";

import { approve, deleteProposal } from "../FirebaseAction";
import { useAvatarUrl } from "presentation/utils/useAvatar";
import { useUsername } from "presentation/utils/useUsername";

export const ProposalCard = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [content, setContent] = useState({
    id: "id",
    name: "name",
    content: "content",
    user: {
      name: "name",
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 削除ボタン用メニュー
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const setMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setMenuClose = () => {
    setAnchorEl(null);
  };

  const ApproveDialogue = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="proposal" />
          <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼Content
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="no" />
            <br />
          </Button>
          <Button
            onClick={() => {
              handleClose();
              approve("proposals", content.id).then((res) => {
                props.setSuccess(res);
              });
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
            <br />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const DeleteDialogue = () => {
    return (
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="delete" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼Content
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={async () => {
              setDeleteOpen(false);
              const result = await deleteProposal(
                props.userid,
                props.proposal.id
              );
              props.setSuccess(result);
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function autoLink(str) {
    let result = false;
    if (str.includes("http")) {
      result = true;
    }
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const eliminateNum = /<@[0-9]+>/;
    if (str.length >= 120) {
      return [
        result,
        str.match(regexp_url),
        str.slice(0, 120).replace(regexp_url, "").replace(eliminateNum, "") +
          "...",
      ];
    } else {
      return [
        result,
        str.match(regexp_url),
        str.replace(regexp_url, "").replace(eliminateNum, ""),
      ];
    }
  }

  // 投票数が増えても表示される🟦🟥が20個を超えないようにする処理
  function ResizeVote(
    agreeVote: number,
    disagreeVote: number
  ): [number, number] {
    const totalVote = agreeVote + disagreeVote;

    // totalVoteを割って、商が20以下となる最初の数を取得
    let minimumDivider = [...Array(1000)]
      .map((_, i) => i + 1)
      .find((i) => totalVote / i <= 20);
    if (minimumDivider === undefined) {
      minimumDivider = 1000;
    }

    if (agreeVote === disagreeVote) {
      return [
        Math.floor(agreeVote / minimumDivider),
        Math.floor(disagreeVote / minimumDivider),
      ];
    } else if (agreeVote > disagreeVote) {
      return [
        Math.ceil(agreeVote / minimumDivider),
        Math.floor(disagreeVote / minimumDivider),
      ];
    } else if (agreeVote < disagreeVote) {
      return [
        Math.floor(agreeVote / minimumDivider),
        Math.ceil(disagreeVote / minimumDivider),
      ];
    }
    return [agreeVote, disagreeVote];
  }

  const NumberOfVote = () => {
    const agreeVote = Number(props.agree);
    const disagreeVote = Number(props.disagree);

    // TODO: このif分岐をなくしたい、agreeとdisagreeの情報はどの画面でも見れるようにしたい
    // https://github.com/UnyteDAO/Unyte-Discord/issues/88
    if (isNaN(agreeVote) || isNaN(disagreeVote)) {
      return "";
    }

    const [resizedAgree, resizedDisagree] = ResizeVote(agreeVote, disagreeVote);

    return `🤝: ${new Array<string>(resizedAgree)
      .fill("🟦")
      .join("")}${new Array<string>(resizedDisagree)
      .fill("🟥")
      .join("")} ⭕️:${agreeVote} ❌:${disagreeVote}`;
  };

  const avatarUrl = useAvatarUrl(props.proposal.user.id);

  const avatarElem = useMemo(() => {
    return props.proposal.twitter !== undefined ? (
      <Avatar src={props.proposal.user.avatarid}></Avatar>
    ) : (
      <Avatar src={avatarUrl}></Avatar>
    );
  }, [avatarUrl, props.proposal.twitter, props.proposal.user.avatarid]);

  const username = useUsername(props.proposal.user.id);

  return (
    <div>
      <ApproveDialogue></ApproveDialogue>
      <DeleteDialogue></DeleteDialogue>
      <Card sx={{ maxWidth: 600, height: 240 }}>
        <CardHeader
          align="center"
          avatar={avatarElem}
          action={
            <div>
              <IconButton aria-label="settings" onClick={setMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={setMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={setMenuClose}
                  component={Link}
                  to={`/profile/${props.proposal.user.id}`}
                >
                  View Profile
                </MenuItem>

                {props.proposal.twitter !== undefined ? (
                  <LinkA
                    href={`https://twitter.com/${props.proposal.user.username}/status/${props.proposal.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <MenuItem onClick={setMenuClose}>View Content</MenuItem>
                  </LinkA>
                ) : props.proposal.channelid !== undefined ? (
                  <LinkA
                    href={`https://discord.com/channels/${props.proposal.teamid}/${props.proposal.channelid}/${props.proposal.id}`}
                    underline="none"
                    target="_blank"
                  >
                    <MenuItem onClick={setMenuClose}>View Content</MenuItem>
                  </LinkA>
                ) : (
                  <Box></Box>
                )}
                {/* {props.userid==props.proposal.user.id && (<MenuItem onClick={setMenuClose}>Delete</MenuItem>)} */}
                {props.userid === props.proposal.user.id && (
                  <MenuItem
                    onClick={async () => {
                      setMenuClose();
                      setContent(props.proposal);
                      setDeleteOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          }
          title={username}
          subheader={
            new Date(props.proposal.createdat).getFullYear().toString() +
            "/" +
            (new Date(props.proposal.createdat).getMonth() + 1).toString() +
            "/" +
            new Date(props.proposal.createdat).getDate().toString()
          }
        />
        <CardActions>
          {props.proposal.approved === "approved" ? (
            <Button
              sx={{
                textTransform: "none",
                minWidth: "135px",
                background: "linear-gradient(45deg, #ff7f50,#ff1493)",
                color: "white",
              }}
            >
              <Typography>
                <FormattedMessage id="app.input.verified" />
              </Typography>
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{ textTransform: "none", minWidth: "135px", color: "gray" }}
              onClick={() => {
                if (props.admin) {
                  setContent(props.proposal);
                  handleClickOpen();
                } else {
                  setContent(props.proposal);
                  // test用。解除しておく
                  // handleClickOpen();
                }
              }}
            >
              <Typography>
                <FormattedMessage id="app.input.verify" />
              </Typography>
            </Button>
          )}
          <div style={{ flexGrow: 1 }}></div>
          {props.proposal.twitter !== undefined ? (
            <Button
              sx={{ marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                window.open(
                  `https://twitter.com/${props.proposal.user.username}/status/${props.proposal.id}`,
                  "_blank"
                );
              }}
            >
              <Typography sx={{ color: "Gray" }}>View on Twitter</Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          ) : props.proposal.channelid !== undefined ? (
            <Button
              sx={{ marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                // 投票機能実装前のproposalに適切なリンクを与えるための一時的な対応
                if (props.proposal.approvalthreshold === undefined) {
                  window.open(
                    `https://discord.com/channels/${props.proposal.teamid}/${props.proposal.channelid}/${props.proposal.id}`,
                    "_blank"
                  );
                } else {
                  window.open(
                    `https://discord.com/channels/${props.proposal.teamid}/${props.proposal.id}`,
                    "_blank"
                  );
                }
              }}
            >
              <Typography sx={{ color: "Gray" }}>View on Discord</Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          ) : (
            <Box></Box>
          )}
        </CardActions>
        <CardContent sx={{ height: 36, textAlign: "center" }}>
          {autoLink(props.proposal.content)[0] ? (
            <Typography>
              {autoLink(props.proposal.content)[2]}
              <LinkA
                href={autoLink(props.proposal.content)[1]}
                underline="none"
                target="_blank"
              >
                (<FormattedMessage id="link" />)
              </LinkA>
            </Typography>
          ) : (
            <Typography>{autoLink(props.proposal.content)}</Typography>
          )}
        </CardContent>
        <CardContent>
          <Grid sx={{ maxWidth: 500, height: 40 }} justifyContent="flex-start">
            <Typography>{NumberOfVote()}</Typography>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
