import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { checkProfilesByTeamId, checkUsers } from "../FirebaseAction";

type profile = {
  id?: string;
  userid: string;
  avatar?: string | null;
  username?: string;
};

type serverProfileContextType = {
  profileList: profile[];
  globalProfileList: profile[];
  teamId: string | null;
  setTeam: (teamId: string) => void;
};

const serverProfileContext = createContext<serverProfileContextType>({
  profileList: [],
  globalProfileList: [],
  teamId: null,
  setTeam: () => {},
});

type profileProviderProps = {
  children: ReactNode;
};

/**
 * 特定のteamIdで指定されたサーバープロフィールを提供する。
 * teamIdは`setTeam()`を用いて指定し、初期値はnullで未指定。
 * ユーザープロフィールについても同時に提供する
 */
const ServerProfileProvider = ({ children }: profileProviderProps) => {
  const [profileList, setProfileList] = useState<profile[]>([]);
  const [globalProfileList, setGlobalProfileList] = useState<profile[]>([]);
  const [teamId, setTeamId] = useState<string | null>(null);

  const setTeam = useCallback((teamId: string) => {
    setTeamId(teamId);
  }, []);

  useEffect(() => {
    (async () => {
      const users = await checkUsers();
      const globalProfileList = users.map((user) => {
        return {
          username: user.username,
          avatar: user.avatar,
          userid: user.id,
        };
      });
      setGlobalProfileList(globalProfileList);
    })();
  }, [teamId]);

  useEffect(() => {
    (async () => {
      if (teamId) {
        const profileList = await checkProfilesByTeamId(teamId);
        setProfileList(profileList);
      }
    })();
  }, [teamId]);

  return (
    <serverProfileContext.Provider
      value={{ profileList, teamId, setTeam, globalProfileList }}
    >
      {children}
    </serverProfileContext.Provider>
  );
};

export default ServerProfileProvider;
export const useServerProfilesContext = () => {
  return useContext(serverProfileContext);
};
