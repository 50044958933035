import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Paper,
  Divider,
  Tooltip,
  Grid,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HelpIcon from "@mui/icons-material/Help";
import {
  newCheckAllAction,
  checkAdmin,
  checkTeam,
  checkToken,
  Team,
} from "./FirebaseAction";
import { useParams } from "react-router-dom";
import Buttons from "./components/Buttons";
import { AuthContext } from "../../App";
import MonoStackedBar from "mono-stacked-bar";
import CircularProgress from "@mui/material/CircularProgress";
import "mono-stacked-bar/dist/index.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import FilterSelect from "./components/FilterSelect";
import ConvertUtils from "../utils/ConvertUtils";

export default function DashBoard() {
  const { teamid = "" } = useParams();
  const [team, setTeam] = useState<Team>();
  // 描画用
  const [success, setSuccess] = React.useState<any>("false");

  const [contribution, setContribution] = React.useState<any[]>([]);
  const [, setUserid] = React.useState(null);
  const setATfunc = useContext(AuthContext) as any;
  const [admin, setAdmin] = React.useState(false);

  const colors = ["#393986", "#6767aa", "#56b5b5"];

  const [maxActivity, setMaxActivity] = React.useState<number>(0);

  const [reward, setReward] = React.useState({
    token: "UNYT",
    icon: "https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg",
    proposal: 10,
    contribution: 10,
    thanks: 10,
  });
  const [tokenIcon, setTokenIcon] = React.useState(
    "https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg"
  );
  // date params
  const [date, setDate] = React.useState<string | null>(
    ConvertUtils.generateSpecificYyyymm(0)
  );
  const [waiting, setWaiting] = React.useState(true);

  useEffect(() => {
    (async () => {
      const resp = await checkTeam(teamid);
      resp && setTeam(resp);
    })();
  }, [teamid]);

  useEffect(() => {
    (async () => {
      setWaiting(true);
      if (!teamid) {
        return;
      }
      const [contributions, maxActivity] = await newCheckAllAction(
        teamid,
        date
      );
      setContribution(contributions);
      setMaxActivity(maxActivity);
      if (setATfunc.tokenState !== null) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
      const defaultState = await checkTeam(teamid);
      if (defaultState) {
        const fetchToken = () => {
          return new Promise<string>(async (resolve, reject) => {
            let icon = "";
            checkTeam(teamid).then(async function (result) {
              if (result) {
                icon = (await checkToken(result.token))?.avatar || "";
                setTokenIcon(icon);
              }
            });
            resolve(icon);
          });
        };
        const tokenIcon = await fetchToken();
        setReward({
          token: defaultState.token,
          icon: tokenIcon,
          proposal: defaultState.reward.proposal,
          contribution: defaultState.reward.contribution,
          thanks: defaultState.reward.thanks,
        });
      }
      setWaiting(false);
    })();
  }, [setATfunc.tokenState, success, teamid, date]);

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    setSuccess(window.innerWidth);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const MemberCard = () => {
    // 削除ボタン用メニュー
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const setMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const setMenuClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <Button
          sx={{
            minWidth: "135px",
            background: "linear-gradient(45deg, #ff7f50,#ff1493)",
            color: "white",
            ml: 7,
            mb: 3,
          }}
        >
          <Typography>トークン送付</Typography>
        </Button>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={2} sx={{ ml: 5, mr: 5 }}>
            {contribution.map((eachCont, index) => {
              return (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ maxWidth: 600, height: 240 }}>
                    <CardHeader
                      align="center"
                      avatar={
                        eachCont.user.twitter !== undefined ? (
                          <Avatar src={eachCont.user.avatar}></Avatar>
                        ) : (
                          <Avatar
                            src={`https://cdn.discordapp.com/avatars/${eachCont.user.id}/${eachCont.user.avatar}`}
                          ></Avatar>
                        )
                      }
                      action={
                        <div>
                          <IconButton
                            aria-label="settings"
                            onClick={setMenuOpen}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={setMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={setMenuClose}
                              component={Link}
                              to={`/profile/${eachCont.user.id}`}
                            >
                              View Profile
                            </MenuItem>
                            {/* {props.userid==eachCont.user.id && (<MenuItem onClick={setMenuClose}>Delete</MenuItem>)} */}
                          </Menu>
                        </div>
                      }
                      title={eachCont.user.name}
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Button
                            sx={{ minWidth: "135px", color: "white", m: 1 }}
                          >
                            <Avatar src={tokenIcon}></Avatar>
                            <Typography sx={{ ml: 3, mr: 3 }}>
                              {0}
                              {reward.token}
                            </Typography>
                          </Button>
                          <br />
                          <Typography>
                            (potential:{eachCont.activity.total * 10}
                            {reward.token})
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
    );
  };

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      {!waiting && (
        <Grid container spacing={2} mb={3} mt={1} ml={5} alignItems="center">
          <Grid item xs={12} md={3} display="flex">
            <Typography variant="h5" className="white" align="center">
              <Tooltip title={<FormattedMessage id="app.help.allocation" />}>
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              Token Allocation:
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button sx={{ minWidth: "135px", color: "white" }}>
              <Avatar src={tokenIcon}></Avatar>
              <Typography sx={{ ml: 3, mr: 3 }}>
                {reward.proposal}
                {reward.token}
              </Typography>
              / Proposal
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button sx={{ minWidth: "135px", color: "white" }}>
              <Avatar src={tokenIcon}></Avatar>
              <Typography sx={{ ml: 3, mr: 3 }}>
                {reward.contribution}
                {reward.token}
              </Typography>
              / Contribution
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button sx={{ minWidth: "135px", color: "white" }}>
              <Avatar src={tokenIcon}></Avatar>
              <Typography sx={{ ml: 3, mr: 3 }}>
                {reward.thanks}
                {reward.token}
              </Typography>
              / Thanks
            </Button>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ mt: 5, mb: 5 }} />
      <div className="loading-container">
        {waiting && (
          <CircularProgress sx={{ mt: 12, mb: 12 }}></CircularProgress>
        )}
      </div>
      {!waiting && (
        <Box display="flex" flexDirection="column">
          <FilterSelect date={date} setDate={setDate} />
          <Box display="flex" justifyContent="center">
            <Grid container>
              {contribution.map((eachContribution, index) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    mb={1}
                    mt={1}
                    ml={5}
                    alignItems="center"
                    key={index}
                  >
                    <Grid item xs={2} md={0.5}>
                      <Avatar
                        alt={eachContribution.user.name}
                        src={
                          eachContribution.user.twitter !== undefined
                            ? eachContribution.user.avatar
                            : `https://cdn.discordapp.com/avatars/${eachContribution.user.id}/${eachContribution.user.avatar}`
                        }
                        sx={{ width: 36, height: 36 }}
                      ></Avatar>
                    </Grid>
                    <Grid item xs={2} md={1.5}>
                      <Typography sx={{ color: "#FFFFFF" }}>
                        {eachContribution.user.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={0.5} sx={{ ml: 4 }}>
                      <Avatar src={tokenIcon}></Avatar>
                    </Grid>
                    <Grid item xs={2} md={0.5} sx={{ mr: 7 }}>
                      <Typography className="white">
                        {eachContribution.user.token?.balance || 0}
                        {reward.token}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={7} alignItems="center">
                      {team && (
                        <MonoStackedBar
                          data={[
                            eachContribution.activity.proposals,
                            eachContribution.activity.contributions,
                            eachContribution.activity.thanks,
                          ]}
                          width={
                            ((size[0] * eachContribution.activity.total) /
                              maxActivity) *
                            0.6
                          }
                          colors={colors}
                          height={36}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <br></br>
          </Box>
        </Box>
      )}
      <Box>
        <Grid container spacing={2} mb={1} mt={1} ml={5}>
          <Grid item xs={12} md={12}>
            <Box sx={{ color: "white" }} className="white">
              <MonoStackedBar
                data={[
                  { value: 10, caption: "proposal" },
                  { value: 10, caption: "contribution" },
                  { value: 10, caption: "thanks(received)" },
                ]}
                // radius={10}
                // height={30}
                displayLabels={false}
                width={600}
                colors={colors}
                height={28}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 5, mb: 5 }} />
      <Box sx={{ m: 10 }}></Box>
      {admin && !waiting && <MemberCard></MemberCard>}
      {/* {!waiting &&<MemberCard></MemberCard>} */}
    </div>
  );
}
