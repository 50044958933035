import React, { useEffect, useMemo, useState } from "react";
import { Typography, Button, IconButton, AvatarGroup } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  approve,
  deleteProposal,
  changeStatus,
  Task,
  getUserInfo,
  Contribution,
  checkContribution,
  Token,
} from "../FirebaseAction";
import { useServerProfilesContext } from "./ServerProfileProvider";
import { useUsername } from "presentation/utils/useUsername";

type props = {
  task: Task;
  token?: Token;
  userid: string | null;
  admin: boolean;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
  shouldHighlight?: boolean;
};

export const TaskCard = ({
  shouldHighlight = false,
  token,
  ...props
}: props) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [creater, setCreater] = useState<any>({});
  const [relatedContributions, setRelatedContributions] = useState<
    Contribution[]
  >([]);
  const { teamid = "" } = useParams();
  const navigate = useNavigate();
  const { profileList, globalProfileList } = useServerProfilesContext();

  useEffect(() => {
    (async () => {
      const creater = await getUserInfo(props.task.createrid);
      const serverProfile = profileList.find(
        (profile) => profile.userid === props.task.createrid
      );
      const globalProfile = globalProfileList.find(
        (profile) => profile.userid === props.task.createrid
      );
      if (serverProfile?.avatar) {
        creater.avatarUrl = `https://cdn.discordapp.com/guilds/${teamid}/users/${props.task.createrid}/avatars/${serverProfile.avatar}`;
      } else if (globalProfile?.avatar) {
        creater.avatarUrl = `https://cdn.discordapp.com/avatars/${props.task.createrid}/${globalProfile.avatar}`;
      }
      setCreater(creater);
    })();
  }, [globalProfileList, profileList, props.task.createrid, teamid]);

  useEffect(() => {
    (async () => {
      const resp = await checkContribution(teamid, props.task.id);
      // TODO: n+1問題が起きているので、checkContributionのクエリを修正して計算量を減らしたい
      // https://qiita.com/muroya2355/items/d4eecbe722a8ddb2568b
      resp.forEach((contribution) => {
        const serverProfile = profileList.find(
          (profile) => profile.userid === contribution.user.id
        );
        const globalProfile = globalProfileList.find(
          (profile) => profile.userid === contribution.user.id
        );
        if (serverProfile?.avatar) {
          contribution.avatarUrl = `https://cdn.discordapp.com/guilds/${teamid}/users/${contribution.user.id}/avatars/${serverProfile.avatar}`;
        } else if (globalProfile?.avatar) {
          contribution.avatarUrl = `https://cdn.discordapp.com/avatars/${contribution.user.id}/${globalProfile.avatar}`;
        }
      });
      setRelatedContributions(resp);
    })();
  }, [
    creater,
    globalProfileList,
    profileList,
    props.task.createrid,
    props.task.id,
    teamid,
  ]);

  const contributerIcons = useMemo(() => {
    return [
      ...new Set(
        relatedContributions.map((contribution) => {
          return contribution.avatarUrl;
        })
      ),
    ];
  }, [relatedContributions]);

  const handleClose = () => {
    setOpen(false);
  };

  // 削除ボタン用メニュー
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const setMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setMenuClose = () => {
    setAnchorEl(null);
  };

  const ApproveDialogue = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {creater.name}さんの提案を承認しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼提案内容
            <br></br>
            {props.task.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>いいえ</Button>
          <Button
            onClick={() => {
              handleClose();
              approve("tasks", props.task.id).then((res) => {
                props.setSuccess(res);
              });
            }}
            autoFocus
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const DeleteDialogue = () => {
    return (
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          以下の提案を削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼提案内容
            <br></br>
            {props.task.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>いいえ</Button>
          <Button
            onClick={async () => {
              setDeleteOpen(false);
              const result = await deleteProposal(props.userid, props.task.id);
              props.setSuccess(result);
            }}
            autoFocus
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [status, setStatus] = React.useState(props.task.status);

  const handleChange = async (event) => {
    setStatus(event.target.value);
    const result = await changeStatus(props.task.id, event.target.value);
    props.setSuccess(result.toString());
  };

  const avatar = useMemo(() => {
    return <Avatar src={creater.avatarUrl}></Avatar>;
  }, [creater.avatarUrl]);

  const createrName = useUsername(creater.id);

  return (
    <div style={{ height: "100%" }}>
      <ApproveDialogue></ApproveDialogue>
      <DeleteDialogue></DeleteDialogue>
      <Card
        sx={{ maxWidth: 600, height: "100%", cursor: "pointer" }}
        elevation={shouldHighlight ? 10 : 1}
        onClick={() => navigate(`/tasks/${teamid}?task=${props.task.id}`)}
      >
        <CardHeader
          align="center"
          avatar={avatar}
          action={
            <div>
              <IconButton aria-label="settings" onClick={setMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={setMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setMenuClose();
                  }}
                  component={Link}
                  to={`/profile/${props.task.createrid}`}
                >
                  View Profile
                </MenuItem>
                <MenuItem
                  onClick={setMenuClose}
                  component={Link}
                  to={`/tasks/${teamid}?task=${props.task.id}`}
                >
                  View Detail
                </MenuItem>
                {/* {props.userid==props.task.user.id && (<MenuItem onClick={setMenuClose}>Delete</MenuItem>)} */}
                {props.userid === props.task.createrid && (
                  <MenuItem
                    onClick={async () => {
                      setMenuClose();
                      setDeleteOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          }
          title={createrName}
          subheader={
            props.task.createdat.toDate().getFullYear().toString() +
            "/" +
            (props.task.createdat.toDate().getMonth() + 1).toString() +
            "/" +
            props.task.createdat.toDate().getDate().toString()
          }
        />
        <CardActions>
          {token && (
            <Button>
              <Avatar style={{ marginRight: 5 }} src={token.avatar} />
              {props.task.reward} {token.name}
            </Button>
          )}
          <div style={{ flexGrow: 1 }}></div>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="status"
              onChange={handleChange}
            >
              <MenuItem value={"wip"}>進行中</MenuItem>
              <MenuItem value={"done"}>完了</MenuItem>
            </Select>
          </FormControl>
        </CardActions>
        <CardContent>
          <Typography>{props.task.content}</Typography>
          <AvatarGroup max={4}>
            {contributerIcons.map((icon) => {
              return <Avatar alt="contributer icon" src={icon} key={icon} />;
            })}
          </AvatarGroup>
        </CardContent>
      </Card>
    </div>
  );
};
