import { useServerProfilesContext } from "presentation/view_interfaces/components/ServerProfileProvider";
import { useMemo } from "react";

/**
 * discrodのアバターアイコンURLを取得する。サーバーアイコンがある場合は優先してそのアイコンURLを返却する。
 * https://discord.com/developers/docs/reference#image-formatting-cdn-endpoints に準拠している
 * @param userid アバターURLを取得したいユーザーのid
 * @returns アバターURL
 */
export const useAvatarUrl = (userid: string) => {
  const { profileList, globalProfileList, teamId } = useServerProfilesContext();
  const avatarId = useMemo(() => {
    const serverProfile = profileList.find(
      (profile) => profile.userid === userid
    );

    if (serverProfile?.avatar) {
      return `https://cdn.discordapp.com/guilds/${teamId}/users/${userid}/avatars/${serverProfile.avatar}`;
    }

    const globalProfile = globalProfileList.find(
      (profile) => profile.userid === userid
    );
    if (globalProfile) {
      return `https://cdn.discordapp.com/avatars/${userid}/${globalProfile.avatar}`;
    }
  }, [globalProfileList, profileList, teamId, userid]);

  return avatarId;
};
