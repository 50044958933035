// Firebase関係
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  deleteDoc,
  orderBy,
  Timestamp,
  FirestoreDataConverter,
  runTransaction,
  collectionGroup,
} from "firebase/firestore";
import { firebaseFirestore } from "../../data/Firebase";
import ConvertUtils from "../utils/ConvertUtils";

export async function AddMember(name, address, role, teamid) {
  console.log(name, address, role, teamid);
  try {
    if (name !== "" && address !== "") {
      // アドレスが既に存在するか検索
      const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
      const docSnap = await getDoc(docRef);
      console.log(docSnap.exists());
      if (docSnap.exists()) {
        // 参加済チームに今回のチームidを追加
        await updateDoc(docRef, {
          name: name,
          role: role,
          team: arrayUnion(teamid),
        });
        return "success";
      } else {
        const usersRef = collection(firebaseFirestore, "users");
        await setDoc(doc(usersRef, address.toLowerCase()), {
          name: name,
          address: address.toLowerCase(),
          role: role,
          team: [teamid],
          id: address.toLowerCase(),
          UNYP: 0,
          UNYT: 0,
          UNYC: 0,
        });
        return "success";
      }
    } else {
      alert("空の値があります🥺");
      return "fail";
    }
  } catch (error) {
    console.log(error);
    return "fail";
  }
}

export function AddProposal(
  title,
  description,
  priority,
  assign,
  due,
  createdBy,
  teamid
) {
  try {
    // 提案者がアクセスしているteamidを取得
    if (
      title !== "" &&
      description !== "" &&
      priority !== "" &&
      assign !== "" &&
      due !== ""
    ) {
      const proposalsRef = collection(firebaseFirestore, "proposals");
      const newDoc = doc(proposalsRef).id;
      setDoc(doc(proposalsRef, newDoc), {
        accepted: false,
        title: title,
        description: description,
        priority: priority,
        assign: assign,
        due: due,
        createdBy: createdBy,
        team: teamid,
        id: newDoc,
      });
      return "success";
    } else {
      alert("空の値があります🥺");
      return "fail";
    }
  } catch (error) {
    return "fail";
  }
}

export async function AcceptProposal(id) {
  console.log(id);
  await updateDoc(doc(firebaseFirestore, "proposals", id.id), {
    accepted: true,
  });
}

export function SubmitOutput(description, link, account, id, team) {
  try {
    if (description !== "" && link !== "" && id !== "" && account !== "") {
      const outputsRef = collection(firebaseFirestore, "outputs");
      const newDoc = doc(outputsRef).id;
      setDoc(doc(outputsRef, newDoc), {
        account: account,
        link: link,
        description: description,
        taskid: id,
        outputid: newDoc,
        team: team,
      });
      return "success";
    } else {
      alert("空の値があります🥺");
      return "fail";
    }
  } catch (error) {
    return "fail";
  }
}

export async function AddTeam(name, description, address) {
  try {
    if (name !== "" && address !== "") {
      // チームを追加
      const teamsRef = collection(firebaseFirestore, "teams");
      const newDoc = doc(teamsRef).id;
      await setDoc(doc(teamsRef, newDoc), {
        name: name,
        description: description,
        owneraddress: address,
        id: newDoc,
      });
      // オーナーの参加チーム一覧に今回のチームを追加(id単位)
      // アドレスが既に存在するか検索
      const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
      const docSnap = await getDoc(docRef);
      console.log(docSnap.exists());
      if (docSnap.exists()) {
        // 参加済チームに今回のチームidを追加
        await updateDoc(docRef, {
          team: arrayUnion(newDoc),
        });
      }
      return "success";
    } else {
      alert("チーム名を入力してください🥺");
      return "fail";
    }
  } catch (error) {
    return "fail";
  }
}

export function SubmitComment(from, to, comment) {
  try {
    if (from !== "" && to !== "" && comment !== "") {
      const commentsRef = collection(firebaseFirestore, "comments");
      const newDoc = doc(commentsRef).id;
      setDoc(doc(commentsRef, newDoc), {
        from: from,
        comment: comment,
        to: to,
        id: newDoc,
      });
      return "success";
    } else {
      alert("コメントを入力してください🥺");
      return "fail";
    }
  } catch (error) {
    return "fail";
  }
}

export async function votingAction(proposalid, userid, vote) {
  const docRef = doc(
    firebaseFirestore,
    `proposals/${proposalid}/voting`,
    userid.toLowerCase()
  );
  const docSnap = await getDoc(docRef);
  const votingsRef = collection(
    firebaseFirestore,
    `proposals/${proposalid}/voting`
  );
  if (docSnap.exists()) {
    await updateDoc(docRef, {
      vote: vote,
    });
    // alert(`${vote}に投票内容を更新しました！`)
  } else {
    await setDoc(doc(votingsRef, userid.toLowerCase()), {
      id: userid.toLowerCase(),
      vote: vote,
    });
    // alert(`${userid}：${vote}で投票しました！`)
  }
  // 提案が未承認の場合、チームメンバー数の2/3にfor数が達しているかを確認、達していた場合には承認済に変更し通知
  const proposalRef = await getDoc(
    doc(firebaseFirestore, `proposals/${proposalid}`)
  );
  console.log(proposalRef.data());
  // チームID取得
  const teamid = proposalRef.data()?.team;
  // ユーザーデータを参照、teamidに該当するチームに所属している人数を確認
  const usersRef = collection(firebaseFirestore, "users");
  const snapshot = await getDocs(usersRef);
  let memberamount = 0;
  snapshot.forEach(async (document) => {
    if (document.data()?.team.includes(teamid)) {
      memberamount += 1;
    } else {
    }
  });
  // forの意思表示をしている人数をカウント
  const snapshot2 = await getDocs(votingsRef);
  let foramount = 0;
  snapshot2.forEach(async (document) => {
    if (document.data()?.vote === "for") {
      foramount += 1;
    } else {
    }
  });
  // チームメンバー数の2/3にfor数が達しているかを確認、達していた場合には承認済に変更し通知
  console.log(foramount, memberamount);
  const border = (memberamount * 2) / 3;
  if (foramount >= border) {
    console.log("過半数に達したので提案は承認されました", border);
    await updateDoc(doc(firebaseFirestore, `proposals/${proposalid}`), {
      accepted: true,
    });
    alert("提案は承認されました");
    return "success";
  } else {
    console.log("まだ過半数ではない");
    return "success";
  }
}

export async function editNickName(id, name) {
  const docRef = doc(firebaseFirestore, "users", id.toLowerCase());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    await updateDoc(docRef, {
      name: name,
    });
    alert(`名前が${name}に変更されました`);
  }
}

export async function countActivity(id) {
  const arr = [] as number[];
  const proposalsRef = collection(firebaseFirestore, "proposals");
  const snapshot = await getDocs(
    query(proposalsRef, where("user.id", "==", id))
  );
  arr.push(snapshot.size);

  const contributionsRef = collection(firebaseFirestore, "contributions");
  const snapshot1 = await getDocs(
    query(contributionsRef, where("user.id", "==", id))
  );
  arr.push(snapshot1.size);

  const commentsRef = collection(firebaseFirestore, "thanks");
  const snapshot2 = await getDocs(query(commentsRef, where("to.id", "==", id)));
  arr.push(snapshot2.size);

  const snapshot3 = await getDocs(
    query(commentsRef, where("from.id", "==", id))
  );
  arr.push(snapshot3.size);
  return arr;
}

export async function countProposal(address) {
  // アドレスが所属するチームを特定、IDを配列に保存
  let teams = [] as any[];
  let ongoing = 0;
  let past = 0;
  const docRef = doc(firebaseFirestore, "users", address.toLowerCase());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // 参加済チームに今回のチームidを追加
    teams = docSnap.data().team;
    // 所属チームIDが含まれるProposalの数をカウント
    const proposalsRef = collection(firebaseFirestore, "proposals");
    const snapshot = await getDocs(proposalsRef);
    snapshot.forEach(async (document) => {
      if (
        teams.includes(document.data()?.team) &&
        document.data()?.accepted === true
      ) {
        past += 1;
      } else if (
        teams.includes(document.data()?.team) &&
        document.data()?.accepted === false
      ) {
        ongoing += 1;
      } else {
      }
    });
    return [ongoing, past];
  } else {
    return [0, 0];
  }
}

export async function checkProposalForProposal(teamid, dateParam) {
  // const proposalsRef = collection(firebaseFirestore, "proposal");
  const proposalsRef = collection(firebaseFirestore, "proposals");
  let snapshot;
  if (dateParam === "ALL") {
    snapshot = await getDocs(
      query(
        proposalsRef,
        // teamidを検索、該当するもののみを抽出
        where("teamid", "==", teamid),
        orderBy("createdat", "desc")
      )
    );
  } else {
    const startPeriod = ConvertUtils.getStartPeriod(dateParam);
    const endPeriod = ConvertUtils.getEndPeriod(dateParam);
    snapshot = await getDocs(
      query(
        proposalsRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", startPeriod),
        where("createdat", "<=", endPeriod),
        orderBy("createdat", "desc")
      )
    );
  }
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  console.dir(arr);
  return arr;
}

// proposalにつけられた賛成と反対の数を取得
export async function checkVoteCount(teamid) {
  // proposalコレクションの中からteamidに一致するproposalを集める
  const proposalsRef = collection(firebaseFirestore, "proposals");
  const snapshot = await getDocs(
    query(
      proposalsRef,
      where("teamid", "==", teamid),
      orderBy("createdat", "desc")
    )
  );

  // agreeとdisagreeの数をリストに追加
  // フィールドがないものは0としてカウント
  const agreeCounts = [] as any[];
  const disagreeCounts = [] as any[];

  snapshot.forEach((doc) => {
    doc.data().agree === undefined
      ? agreeCounts.push("0")
      : agreeCounts.push(doc.data().agree);
    doc.data().disagree === undefined
      ? disagreeCounts.push("0")
      : disagreeCounts.push(doc.data().disagree);
  });

  return [agreeCounts, disagreeCounts];
}

// 自分が提案したproposalのみ取得
export async function checkMyProposal(userid) {
  const proposalsRef = collection(firebaseFirestore, "proposals");
  const snapshot = await getDocs(
    query(
      proposalsRef,
      where("user.id", "==", userid),
      orderBy("createdat", "desc")
    )
  );
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}

// 提案を削除
export async function deleteProposal(userid, proposalid) {
  const docRef = doc(firebaseFirestore, "proposals", proposalid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().user.id === userid) {
    await deleteDoc(doc(firebaseFirestore, "proposals", proposalid));
    return proposalid;
  } else {
    return "fail";
  }
}
type contributer = {
  avatarid: string;
  id: string;
  name: string;
};
type contributionStatus = "yet" | "wip" | "done";
export type Contribution = {
  id: string;
  approved: contributionStatus;
  taskid: string | null;
  completed: boolean;
  content: string;
  replied: boolean;
  createdat: Timestamp;
  twitter: boolean;
  teamid: string;
  avatarUrl?: string;
  user: contributer;
};

// 全てのContributionを取得
export async function checkContribution(teamid: string, taskid?: string) {
  // teamidを検索、該当するもののみを抽出
  const contributionsRef = collection(firebaseFirestore, "contributions");
  // const contributionsRef = collection(firebaseFirestore, "contribution");
  // const contributionsRef = collection(firebaseFirestore, "others");
  const snapshot = await getDocs(
    taskid
      ? query(
          contributionsRef,
          where("teamid", "==", teamid),
          where("taskid", "==", taskid),
          orderBy("createdat", "desc")
        )
      : query(
          contributionsRef,
          where("teamid", "==", teamid),
          where("taskid", "==", null),
          orderBy("createdat", "desc")
        )
  );
  const arr = [] as Contribution[];
  snapshot.forEach(async (document) => {
    arr.push(document.data() as Contribution);
  });
  return arr;
}
export async function checkContributionForList(
  teamid: string,
  dateParam: string | null,
  taskid?: string
) {
  const contributionsRef = collection(firebaseFirestore, "contributions");
  let snapshot;
  if (dateParam === "ALL") {
    snapshot = await getDocs(
      taskid
        ? query(
            contributionsRef,
            where("teamid", "==", teamid),
            where("taskid", "==", taskid),
            orderBy("createdat", "desc")
          )
        : query(
            contributionsRef,
            where("teamid", "==", teamid),
            where("taskid", "==", null),
            orderBy("createdat", "desc")
          )
    );
  } else {
    const startPeriod = ConvertUtils.getStartPeriod(dateParam);
    const endPeriod = ConvertUtils.getEndPeriod(dateParam);
    snapshot = await getDocs(
      taskid
        ? query(
            contributionsRef,
            where("teamid", "==", teamid),
            where("taskid", "==", taskid),
            where("createdat", ">=", startPeriod),
            where("createdat", "<=", endPeriod),
            orderBy("createdat", "desc")
          )
        : query(
            contributionsRef,
            where("teamid", "==", teamid),
            where("taskid", "==", null),
            where("createdat", ">=", startPeriod),
            where("createdat", "<=", endPeriod),
            orderBy("createdat", "desc")
          )
    );
  }
  const arr = [] as Contribution[];
  snapshot.forEach(async (document) => {
    arr.push(document.data() as Contribution);
  });
  return arr;
}

// 自分が提案したContributionのみ取得
export async function checkMyContribution(userid) {
  const proposalsRef = collection(firebaseFirestore, "contributions");
  const snapshot = await getDocs(
    query(
      proposalsRef,
      where("user.id", "==", userid),
      orderBy("createdat", "desc")
    )
  );
  const arr = [] as any;
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}

// 貢献を削除
export async function deleteContribution(userid, contributionid) {
  const docRef = doc(firebaseFirestore, "contributions", contributionid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().user.id === userid) {
    await deleteDoc(doc(firebaseFirestore, "contributions", contributionid));
    return contributionid;
  } else {
    return "fail";
  }
}

// thxを削除
export async function deleteThanks(userid, thanksid) {
  const docRef = doc(firebaseFirestore, "thanks", thanksid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().from.id === userid) {
    await deleteDoc(doc(firebaseFirestore, "thanks", thanksid));
    return thanksid;
  } else {
    return "fail";
  }
}

// 全てのthanksを取得
export async function checkThank(teamid) {
  // teamidを検索、該当するもののみを抽出
  const thanksRef = collection(firebaseFirestore, "thanks");
  const snapshot = await getDocs(
    query(
      thanksRef,
      where("teamid", "==", teamid),
      orderBy("createdat", "desc")
    )
  );
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}
export async function newCheckThank(teamid, dateParam) {
  // teamidを検索、該当するもののみを抽出
  const thanksRef = collection(firebaseFirestore, "thanks");
  let snapshot;
  if (dateParam === "ALL") {
    snapshot = await getDocs(
      query(
        thanksRef,
        where("teamid", "==", teamid),
        orderBy("createdat", "desc")
      )
    );
  } else {
    const startPeriod = ConvertUtils.getStartPeriod(dateParam);
    const endPeriod = ConvertUtils.getEndPeriod(dateParam);
    snapshot = await getDocs(
      query(
        thanksRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", startPeriod),
        where("createdat", "<=", endPeriod),
        orderBy("createdat", "desc")
      )
    );
  }
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}

// 全てのthanksを取得
export async function checkMyThanks(userid) {
  const thanksRef = collection(firebaseFirestore, "thanks");
  const snapshot = await getDocs(
    query(thanksRef, where("to.id", "==", userid), orderBy("createdat", "desc"))
  );
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}

// 自分があげた全てのthanksを取得
export async function checkMyThanksGave(userid) {
  const thanksRef = collection(firebaseFirestore, "thanks");
  const snapshot = await getDocs(
    query(
      thanksRef,
      where("from.id", "==", userid),
      orderBy("createdat", "desc")
    )
  );
  const arr = [] as any[];
  snapshot.forEach(async (document) => {
    arr.push(document.data());
  });
  return arr;
}

async function addReward(type: approveType, id: string) {
  const proposalsRef = doc(firebaseFirestore, type, id);
  const docSnap = await getDoc(proposalsRef);
  if (docSnap.exists()) {
    // チームのデータ
    const teamid = docSnap.data().teamid;

    // ユーザーのデータ(thanksの場合のみ挙動が変わる)
    let docSnapUser;
    if (type === "thanks") {
      const usersRef = doc(firebaseFirestore, "users", docSnap.data().to.id);
      docSnapUser = await getDoc(usersRef);
    } else {
      const usersRef = doc(firebaseFirestore, "users", docSnap.data().user.id);
      docSnapUser = await getDoc(usersRef);
    }

    if (docSnapUser.exists()) {
      // 報酬についてのデータ
      const tokenRef = doc(
        firebaseFirestore,
        `users/${docSnapUser.data().id}/token`,
        teamid
      );
      const docSnapToken = await getDoc(tokenRef);

      let rewards = {} as any;
      let formerAmount = 0;
      let addAmount = 0;

      // トークンについてのデータが存在するかの確認
      if (docSnapToken.exists()) {
        try {
          formerAmount = docSnapToken.data().balance || 0;
          console.log(formerAmount);
        } catch (e) {
          console.log(e);
        }
      }

      // チームの報酬ルールを確認
      const teamsRef = doc(firebaseFirestore, "teams", teamid);
      const docTeam = await getDoc(teamsRef);
      if (docTeam.exists()) {
        rewards = docTeam.data().reward;
      }

      // 該当する部分の数値を足して変更
      if (Object.keys(rewards).length > 0) {
        console.log(formerAmount);
        if (type === "proposals") {
          addAmount = rewards.proposal;
          const rewardSum = formerAmount + addAmount;
          console.log(addAmount);
          await setDoc(
            tokenRef,
            {
              id: teamid,
              balance: rewardSum,
            },
            { merge: true }
          );
        } else if (type === "contributions") {
          addAmount = rewards.contribution;
          const rewardSum = formerAmount + addAmount;
          await setDoc(
            tokenRef,
            {
              id: teamid,
              balance: rewardSum,
            },
            { merge: true }
          );
        } else if (type === "thanks") {
          addAmount = rewards.thanks;
          const rewardSum = formerAmount + addAmount;
          await setDoc(
            tokenRef,
            {
              id: teamid,
              balance: rewardSum,
            },
            { merge: true }
          );
        }
      }
    }
  }
}

type approveType = "proposals" | "contributions" | "thanks" | "tasks";
type approveFuntion = {
  (type: approveType, id: string): Promise<string>;
  (
    type: "contributionToTask",
    id: string,
    teamId: string,
    senderId: string,
    recieverId: string,
    ammount: number
  ): Promise<string>;
};

export const approve: approveFuntion = async (
  type: approveType | "contributionToTask",
  id: string,
  teamId = "",
  senderId?: string,
  recieverId?: string,
  ammount?: number
) => {
  const collectionName = type === "contributionToTask" ? "contributions" : type;
  const targetRef = doc(firebaseFirestore, collectionName, id);
  const docSnap = await getDoc(targetRef);
  // 報酬額を計算して更新
  type !== "contributionToTask" && (await addReward(type, id));
  // ユーザー情報がすでに登録してあれば値を更新、なければ登録して数値をセット
  if (docSnap.exists()) {
    switch (type) {
      case "proposals": {
        await updateDoc(targetRef, {
          approved: "approved",
        });
        // proposalの場合のみ、承認後にタスクとして登録
        console.log("タスク登録");
        const tasksRef = doc(firebaseFirestore, "tasks", id);
        const docSnapTask = await getDoc(tasksRef);
        if (docSnapTask.exists()) {
        } else {
          console.log("タスク登録中");
          await setDoc(tasksRef, docSnap.data());
          await updateDoc(tasksRef, {
            status: "yet",
          });
        }
        console.log("タスク登録完了");
        // ユーザー情報の変更
        const usersRef = doc(
          firebaseFirestore,
          "users",
          docSnap.data().user.id
        );
        const docSnapUser = await getDoc(usersRef);
        if (docSnapUser.exists()) {
          const newCont = docSnapUser.data().approveCounts.contribution;
          const newProposal = docSnapUser.data().approveCounts.proposal + 1;
          const newThankGive = docSnapUser.data().approveCounts.thxGive;
          const newThankTake = docSnapUser.data().approveCounts.thxTake;
          await updateDoc(usersRef, {
            approveCounts: {
              contribution: newCont,
              proposal: newProposal,
              thxGive: newThankGive,
              thxTake: newThankTake,
            },
          });
        }
        break;
      }
      case "contributions": {
        await updateDoc(targetRef, {
          approved: "approved",
        });
        const usersRef = doc(
          firebaseFirestore,
          "users",
          docSnap.data().user.id
        );
        const docSnapUser = await getDoc(usersRef);
        if (docSnapUser.exists()) {
          const newCont = docSnapUser.data().approveCounts.contribution + 1;
          const newProposal = docSnapUser.data().approveCounts.proposal;
          const newThankGive = docSnapUser.data().approveCounts.thxGive;
          const newThankTake = docSnapUser.data().approveCounts.thxTake;
          await updateDoc(usersRef, {
            approveCounts: {
              contribution: newCont,
              proposal: newProposal,
              thxGive: newThankGive,
              thxTake: newThankTake,
            },
          });
        }
        break;
      }
      case "thanks": {
        await updateDoc(targetRef, {
          approved: "approved",
        });
        const docSnapUserTo = await getDoc(
          doc(firebaseFirestore, "users", docSnap.data().to.id)
        );
        const docSnapUserFrom = await getDoc(
          doc(firebaseFirestore, "users", docSnap.data().from.id)
        );
        console.log(docSnapUserTo.exists() && docSnapUserFrom.exists());
        if (docSnapUserTo.exists() && docSnapUserFrom.exists()) {
          const newCont = docSnapUserTo.data().approveCounts.contribution;
          const newProposal = docSnapUserTo.data().approveCounts.proposal;
          const newThankGive = docSnapUserTo.data().approveCounts.thxGive;
          const newThankTake = docSnapUserTo.data().approveCounts.thxTake + 1;
          await updateDoc(
            doc(firebaseFirestore, "users", docSnap.data().to.id),
            {
              approveCounts: {
                contribution: newCont,
                proposal: newProposal,
                thxGive: newThankGive,
                thxTake: newThankTake,
              },
            }
          );
          const newContFrom = docSnapUserFrom.data().approveCounts.contribution;
          const newProposalFrom = docSnapUserFrom.data().approveCounts.proposal;
          const newThankGiveFrom =
            docSnapUserFrom.data().approveCounts.thxGive + 1;
          const newThankTakeFrom = docSnapUserFrom.data().approveCounts.thxTake;
          await updateDoc(
            doc(firebaseFirestore, "users", docSnap.data().from.id),
            {
              approveCounts: {
                contribution: newContFrom,
                proposal: newProposalFrom,
                thxGive: newThankGiveFrom,
                thxTake: newThankTakeFrom,
              },
            }
          );
        }
        break;
      }
      case "contributionToTask": {
        if (senderId && teamId && recieverId && ammount) {
          await runTransaction(firebaseFirestore, async (transaction) => {
            const senderAccountDoc = doc(
              firebaseFirestore,
              "users",
              senderId,
              "token",
              teamId
            ).withConverter(balanceConverter);
            const senderSnapshot = await transaction.get(senderAccountDoc);
            const senderAccount = senderSnapshot.data();
            const recieverAccountDoc = doc(
              firebaseFirestore,
              "users",
              recieverId,
              "token",
              teamId
            ).withConverter(balanceConverter);
            const recieverSnapshot = await transaction.get(recieverAccountDoc);
            const recieverData = recieverSnapshot.data();
            const recieverAccount = recieverData
              ? recieverData
              : { id: teamId, balance: 0 };

            if (
              !senderAccount ||
              senderAccount.balance < ammount ||
              recieverAccount.balance < -ammount // 負の報酬を実行するための検証
            ) {
              throw new Error("Insufficient balance...");
            }

            if (senderId !== recieverId) {
              transaction.set(senderAccountDoc, {
                ...senderAccount,
                balance: senderAccount.balance - ammount,
              });

              transaction.set(recieverAccountDoc, {
                ...recieverAccount,
                balance: recieverAccount.balance + ammount,
              });
            }

            transaction.update(targetRef, {
              approved: "approved",
            });
          });
        }
        break;
      }
      default:
        break;
    }
  }
  return id;
};

type taskStatus = "wip" | "done";
export type Task = {
  id: string;
  content: string;
  createrid: string;
  createdat: Timestamp;
  reward: number;
  status: taskStatus;
  teamid: string;
};

const taskConverter: FirestoreDataConverter<Task | null> = {
  toFirestore: (post: Task) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isTask(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

const isTask = (value: unknown): value is Task => {
  if (
    typeof value === "object" &&
    value !== null &&
    typeof value["id"] === "string" &&
    typeof value["content"] === "string" &&
    typeof value["createrid"] === "string" &&
    typeof value["reward"] === "number" &&
    typeof value["status"] === "string" &&
    typeof value["teamid"] === "string" &&
    typeof value["createdat"] === "object"
  ) {
    return true;
  }
  return false;
};

export async function checkTask(taskid: string) {
  const taskRef = doc(firebaseFirestore, "tasks", taskid).withConverter(
    taskConverter
  );
  const taskDoc = await getDoc(taskRef);
  return taskDoc.data();
}

export async function checkTasks(teamid: string) {
  const tasksRef = collection(firebaseFirestore, "tasks").withConverter(
    taskConverter
  );
  const snapshot = await getDocs(
    query(tasksRef, where("teamid", "==", teamid), orderBy("createdat", "desc"))
  );
  const arr = [] as Task[];
  snapshot.forEach(async (document) => {
    const data = document.data();
    if (data) {
      arr.push(data);
    }
  });
  return arr;
}
export async function newCheckTasks(teamid: string, dateParam: string | null) {
  const tasksRef = collection(firebaseFirestore, "tasks").withConverter(
    taskConverter
  );
  let snapshot;
  if (dateParam === "ALL") {
    snapshot = await getDocs(
      query(
        tasksRef,
        where("teamid", "==", teamid),
        orderBy("createdat", "desc")
      )
    );
  } else {
    const startPeriod = ConvertUtils.getStartPeriod(dateParam);
    const dateStartPeriod = new Date(startPeriod);
    const firesroreTimeStart = Timestamp.fromDate(dateStartPeriod);
    const endPeriod = ConvertUtils.getEndPeriod(dateParam);
    const dateEndPeriod = new Date(endPeriod);
    const firesroreTimeEnd = Timestamp.fromDate(dateEndPeriod);
    snapshot = await getDocs(
      query(
        tasksRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", firesroreTimeStart),
        where("createdat", "<=", firesroreTimeEnd),
        orderBy("createdat", "desc")
      )
    );
  }
  const arr = [] as Task[];
  snapshot.forEach(async (document) => {
    const data = document.data();
    if (data) {
      arr.push(data);
    }
  });
  return arr;
}

// 自分が提案したtaskのみ取得
export async function checkMyTask(username) {
  const contributionsRef = collection(firebaseFirestore, "contributions");
  const snapshot = await getDocs(
    query(contributionsRef, where("username", "==", username))
  );
  const arr = [] as string[];
  snapshot.forEach(async (document) => {
    arr.push(document.data()?.id);
  });
  return arr;
}

export async function searchUser(id) {
  return new Promise<any>(async (resolve, reject) => {
    const docRef = doc(firebaseFirestore, "users", id);
    // const docRef = doc(firebaseFirestore, "usertest", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      resolve(docSnap.data());
    } else {
      resolve("anonymous");
    }
  });
}

export async function checkAdmin(teamid: string, userid: string) {
  return new Promise<boolean>(async (resolve, reject) => {
    const docRef = doc(firebaseFirestore, "teams", teamid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admin.includes(userid)) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
}
export async function checkAdminId(teamid) {
  return new Promise<string[]>(async (resolve, reject) => {
    const docRef = doc(firebaseFirestore, "teams", teamid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      resolve(docSnap.data().admin);
    } else {
      resolve([]);
    }
  });
}
export async function registerAdmin(teamid, userid) {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(firebaseFirestore, "teams", teamid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        admin: arrayUnion(userid),
      });
      resolve(Date.now());
    } else {
      resolve(false);
    }
  });
}

// ユーザーの情報をfirestoreに登録
export async function setUser(user) {
  console.log(user);
  const docRef = doc(firebaseFirestore, "users", user.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // console.log("doc exists")
  } else {
    await setDoc(docRef, user);
    await updateDoc(docRef, {
      counts: {
        proposal: 0,
        contribution: 0,
        thxGive: 0,
        thxTake: 0,
      },
      approveCounts: {
        proposal: 0,
        contribution: 0,
        thxGive: 0,
        thxTake: 0,
      },
    });
  }
}

export type Balance = {
  id: string;
  balance: number;
};

const isBalance = (value: unknown): value is Balance => {
  if (
    typeof value === "object" &&
    value !== null &&
    typeof value["id"] === "string" &&
    typeof value["balance"] === "number"
  ) {
    return true;
  }
  return false;
};

const balanceConverter: FirestoreDataConverter<Balance | null> = {
  toFirestore: (post: Balance) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isBalance(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

export const fetchUserBalance = async (userId: string, teamId: string) => {
  const balanceDocRef = doc(
    firebaseFirestore,
    "users",
    userId,
    "token",
    teamId
  );
  const balanceDoc = await getDoc(balanceDocRef);
  return balanceDoc?.data();
};

export const getUserInfo = async (userid: string) => {
  let user = {} as any;
  // それぞれのidからユーザー情報を取得
  const docRef = doc(firebaseFirestore, "users", userid);
  // const docRef = doc(firebaseFirestore, "usertest", userid);
  await getDoc(docRef).then(function (result) {
    if (result.exists()) {
      if (result.data().twitter !== undefined) {
        user = {
          id: result.data().id,
          name: result.data().name,
          avatar: result.data().avatar,
          twitter: true,
        };
      } else {
        user = {
          id: result.data().id,
          name: result.data().username,
          avatar: result.data().avatar,
        };
      }
    } else {
      user = {
        id: "anonymous",
        name: "anonymous",
        avatar: "anonymous",
      };
    }
  });
  return user;
};

export async function checkAllAction(
  teamid: string
): Promise<[contributions: any[], maxActivity: number]> {
  let maxActivity = 0;
  // チームIDに紐づく提案・貢献・サンクスを全て取得
  const proposalsRef = collection(firebaseFirestore, "proposals");
  const snapshotProposal = await getDocs(
    query(
      proposalsRef,
      where("teamid", "==", teamid),
      where("approved", "==", "approved")
    )
  );
  const contributionsRef = collection(firebaseFirestore, "contributions");
  // const contributionsRef = collection(firebaseFirestore, "others");
  const snapshotContribution = await getDocs(
    query(
      contributionsRef,
      where("teamid", "==", teamid),
      where("approved", "==", "approved")
    )
  );
  const thanksRef = collection(firebaseFirestore, "thanks");
  const snapshotThanks = await getDocs(
    query(
      thanksRef,
      where("teamid", "==", teamid),
      where("approved", "==", "approved")
    )
  );

  // userの出てくる回数をカウント(proposal)
  const arr = [] as any[];
  const countProposal = {};
  snapshotProposal.forEach(async (document) => {
    arr.push(document.data()?.user.id);
  });
  for (let i = 0; i < arr.length; i++) {
    const elm = arr[i];
    countProposal[elm] = (countProposal[elm] || 0) + 1;
  }

  // userの出てくる回数をカウント(contribution)
  const arr2 = [] as string[];
  const countContribution = {} as any;
  snapshotContribution.forEach(async (document) => {
    arr2.push(document.data()?.user.id);
  });
  for (let i = 0; i < arr2.length; i++) {
    const elm = arr2[i];
    countContribution[elm] = (countContribution[elm] || 0) + 1;
  }

  // userの出てくる回数をカウント(thanks)
  const arr3 = [] as any[];
  const countThanks = {} as any;
  snapshotThanks.forEach(async (document) => {
    arr3.push(document.data()?.to.id);
  });
  for (let i = 0; i < arr3.length; i++) {
    const elm = arr3[i];
    countThanks[elm] = (countThanks[elm] || 0) + 1;
  }

  // えられた結果を一つの配列にまとめる
  const arrayMerged = Array.from(new Set([...arr, ...arr2, ...arr3]));

  // 結果を格納する配列を定義
  const resultArr = [] as any[];
  // それぞれのIDの実績値を連想配列から取得
  arrayMerged.forEach(async (userid) => {
    const getActivity = (userid) => {
      let proposals = 0;
      let contributions = 0;
      let thanks = 0;
      if (countProposal[userid] !== undefined) {
        proposals = countProposal[userid];
      }
      if (countContribution[userid] !== undefined) {
        contributions = countContribution[userid];
      }
      if (countThanks[userid] !== undefined) {
        thanks = countThanks[userid];
      }
      const activity = {
        proposals: proposals,
        contributions: contributions,
        thanks: thanks,
        total: proposals + contributions + thanks,
      };
      if (maxActivity < activity.total) {
        maxActivity = activity.total;
      }
      return activity;
    };

    const activity = getActivity(userid);
    const user = await getUserInfo(userid);
    const balance = await fetchUserBalance(userid, teamid);
    resultArr.push({
      user: { ...user, token: balance },
      activity: activity,
    });
    // 降順でソート
    resultArr.sort((a, b) => b.activity.total - a.activity.total);
  });
  return [resultArr, maxActivity];
}
export async function newCheckAllAction(
  teamid: string,
  dateParam: string | null
): Promise<[contributions: any[], maxActivity: number]> {
  let maxActivity = 0;

  // チームIDに紐づく提案・貢献・サンクスを全て取得
  const proposalsRef = collection(firebaseFirestore, "proposals");
  const contributionsRef = collection(firebaseFirestore, "contributions");
  // const contributionsRef = collection(firebaseFirestore, "others");
  const thanksRef = collection(firebaseFirestore, "thanks");

  let snapshotProposal;
  let snapshotContribution;
  let snapshotThanks;
  if (dateParam === "ALL") {
    snapshotProposal = await getDocs(
      query(
        proposalsRef,
        where("teamid", "==", teamid),
        where("approved", "==", "approved")
      )
    );
    snapshotContribution = await getDocs(
      query(
        contributionsRef,
        where("teamid", "==", teamid),
        where("approved", "==", "approved")
      )
    );
    snapshotThanks = await getDocs(
      query(
        thanksRef,
        where("teamid", "==", teamid),
        where("approved", "==", "approved")
      )
    );
  } else {
    const startPeriod = ConvertUtils.getStartPeriod(dateParam);
    const endPeriod = ConvertUtils.getEndPeriod(dateParam);
    snapshotProposal = await getDocs(
      query(
        proposalsRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", startPeriod),
        where("createdat", "<=", endPeriod),
        where("approved", "==", "approved")
      )
    );
    snapshotContribution = await getDocs(
      query(
        contributionsRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", startPeriod),
        where("createdat", "<=", endPeriod),
        where("approved", "==", "approved")
      )
    );
    snapshotThanks = await getDocs(
      query(
        thanksRef,
        where("teamid", "==", teamid),
        where("createdat", ">=", startPeriod),
        where("createdat", "<=", endPeriod),
        where("approved", "==", "approved")
      )
    );
  }

  // userの出てくる回数をカウント(proposal)
  const arr = [] as any[];
  const countProposal = {};
  snapshotProposal.forEach((document) => {
    arr.push(document.data()?.user.id);
  });
  for (let i = 0; i < arr.length; i++) {
    const elm = arr[i];
    countProposal[elm] = (countProposal[elm] || 0) + 1;
  }

  // userの出てくる回数をカウント(contribution)
  const arr2 = [] as string[];
  const countContribution = {} as any;
  snapshotContribution.forEach((document) => {
    arr2.push(document.data()?.user.id);
  });
  for (let i = 0; i < arr2.length; i++) {
    const elm = arr2[i];
    countContribution[elm] = (countContribution[elm] || 0) + 1;
  }

  // userの出てくる回数をカウント(thanks)
  const arr3 = [] as any[];
  const countThanks = {} as any;
  snapshotThanks.forEach((document) => {
    arr3.push(document.data()?.to.id);
  });
  for (let i = 0; i < arr3.length; i++) {
    const elm = arr3[i];
    countThanks[elm] = (countThanks[elm] || 0) + 1;
  }

  // えられた結果を一つの配列にまとめる
  const arrayMerged = Array.from(new Set([...arr, ...arr2, ...arr3]));

  // ▼ old code
  // // 結果を格納する配列を定義
  // const resultArr = [] as any[];
  // // それぞれのIDの実績値を連想配列から取得
  // arrayMerged.forEach(async (userid) => {
  //   const getActivity = (userid) => {
  //     let proposals = 0;
  //     let contributions = 0;
  //     let thanks = 0;
  //     if (countProposal[userid] !== undefined) {
  //       proposals = countProposal[userid];
  //     }
  //     if (countContribution[userid] !== undefined) {
  //       contributions = countContribution[userid];
  //     }
  //     if (countThanks[userid] !== undefined) {
  //       thanks = countThanks[userid];
  //     }
  //     const activity = {
  //       proposals: proposals,
  //       contributions: contributions,
  //       thanks: thanks,
  //       total: proposals + contributions + thanks,
  //     };
  //     if (maxActivity < activity.total) {
  //       maxActivity = activity.total;
  //     }
  //     return activity;
  //   };

  //   const activity = getActivity(userid);
  //   const user = await getUserInfo(userid);
  //   const balance = await fetchUserBalance(userid, teamid);
  //   resultArr.push({
  //     user: { ...user, token: balance },
  //     activity: activity,
  //   });
  //   // 降順でソート
  //   resultArr.sort((a, b) => b.activity.total - a.activity.total);
  // });
  // ▲
  const processArrayMerged = async () => {
    const promises = arrayMerged.map(async (userid) => {
      const getActivity = (userid) => {
        let proposals = 0;
        let contributions = 0;
        let thanks = 0;
        if (countProposal[userid] !== undefined) {
          proposals = countProposal[userid];
        }
        if (countContribution[userid] !== undefined) {
          contributions = countContribution[userid];
        }
        if (countThanks[userid] !== undefined) {
          thanks = countThanks[userid];
        }
        const activity = {
          proposals: proposals,
          contributions: contributions,
          thanks: thanks,
          total: proposals + contributions + thanks,
        };
        if (maxActivity < activity.total) {
          maxActivity = activity.total;
        }
        return activity;
      };

      const activity = getActivity(userid);
      const user = await getUserInfo(userid);
      const balance = await fetchUserBalance(userid, teamid);
      return {
        user: { ...user, token: balance },
        activity: activity,
      };
    });

    const resultArr = await Promise.all(promises);
    // 降順でソート
    resultArr.sort((a, b) => b.activity.total - a.activity.total);
    return resultArr;
  };

  return processArrayMerged().then((resultArr) => {
    return [resultArr, maxActivity];
  });
}

export async function changeStatus(taskid, status) {
  console.log(taskid, status);
  await updateDoc(doc(firebaseFirestore, "tasks", taskid), {
    status: status,
  });
  return Date.now();
}

export async function storeSetting(teamid, reward) {
  const docRef = doc(firebaseFirestore, "teams", teamid);
  await getDoc(docRef);
  await updateDoc(doc(firebaseFirestore, "teams", teamid), {
    token: reward.token,
    reward: {
      proposal: reward.proposal,
      contribution: reward.contribution,
      thanks: reward.thanks,
    },
    approvalthreshold: reward.approvalthreshold,
  });
  return Date.now();
}

export type Team = {
  admin: string[];
  reward: {
    contribution: number;
    proposal: number;
    thanks: number;
  };
  token: string;
  commands?: any[];
  approvalthreshold: number;
};

const teamConverter: FirestoreDataConverter<Team | null> = {
  toFirestore: (post: Team) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isTeam(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

const isTeam = (value: unknown): value is Team => {
  if (
    typeof value === "object" &&
    value !== null &&
    typeof value["token"] === "string" &&
    typeof value["admin"] === "object"
  ) {
    return true;
  }
  return false;
};

export async function checkTeam(teamid: string) {
  const docRef = doc(firebaseFirestore, "teams", teamid).withConverter(
    teamConverter
  );
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export type Token = {
  avatar: string;
  id: string;
  name: string;
};

const isToken = (value: unknown): value is Token => {
  if (
    typeof value === "object" &&
    value !== null &&
    typeof value["avatar"] === "string" &&
    typeof value["id"] === "string" &&
    typeof value["name"] === "string"
  ) {
    return true;
  }
  return false;
};

const tokenConverter: FirestoreDataConverter<Token | null> = {
  toFirestore: (post: Token) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isToken(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

export async function checkToken(tokenId: string) {
  const docRef = doc(firebaseFirestore, "token", tokenId).withConverter(
    tokenConverter
  );
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export type AllToken = {
  team: Team;
  token: string;
  balance: number;
};
export async function checkAllToken(userid: string) {
  const usersTokenRef = collection(
    firebaseFirestore,
    `users/${userid}/token`
  ).withConverter(balanceConverter);
  const snapshot = await getDocs(usersTokenRef);
  const optionalResult = snapshot.docs
    .flatMap((document) => {
      const data = document.data();
      return data ? data : [];
    })
    .map(async ({ id, balance }) => {
      const team = await checkTeam(id);
      const token = team && (await checkToken(team.token));
      return (
        team &&
        token &&
        ({
          team,
          token: token.avatar,
          balance,
        } as AllToken)
      );
    });

  const result = await Promise.all(optionalResult);
  return result.flatMap((result) => (result ? result : []));
}

export async function checkUsersToken(usersTokenArr) {
  return new Promise<any>(async (resolve, reject) => {
    const tokenContentArr = [] as any[];
    usersTokenArr.forEach(async (tokenData) => {
      const teamid = tokenData.id;
      console.log(teamid);
      const teamRef = doc(firebaseFirestore, "teams", teamid);
      const snapshotTeam = await getDoc(teamRef);
      console.log(snapshotTeam.exists());
      const tokenid = snapshotTeam.data()?.token;
      const tokenRef = doc(firebaseFirestore, `token`, tokenid);
      await getDoc(tokenRef).then(async function (result) {
        console.log(result.data());
        tokenContentArr.push(result.data());
        resolve(result.data());
      });
    });
  });
}

export async function fetchTeams() {
  const teamArr = [] as any[];
  const teamsRef = collection(firebaseFirestore, `teams`);
  const snapshot = await getDocs(teamsRef);
  snapshot.forEach((document) => {
    teamArr.push(document.data());
  });
  return teamArr;
}

export type Profile = {
  avatar?: string;
  username?: string;
  userid: string;
  id: string;
};

const isProfile = (value: unknown): value is Profile => {
  if (
    typeof value === "object" &&
    value !== null &&
    typeof value["userid"] === "string" &&
    typeof value["id"] === "string"
  ) {
    return true;
  }
  return false;
};

const profileConverter: FirestoreDataConverter<Profile | null> = {
  toFirestore: (post: Profile) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isProfile(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

export async function checkProfilesByTeamId(teamId: string) {
  const profilesRef = collectionGroup(
    firebaseFirestore,
    "profiles"
  ).withConverter(profileConverter);
  const snapshot = await getDocs(query(profilesRef, where("id", "==", teamId)));
  return snapshot.docs.flatMap((doc) => {
    const data = doc.data();
    return data ? data : [];
  });
}

export type User = {
  username: string;
  id: string;
  avatar: string | null;
};

const isUser = (value: unknown): value is User => {
  if (
    typeof value === "object" &&
    value !== null &&
    (typeof value["avatar"] === "string" || value["avatar"] === null) &&
    typeof value["username"] === "string" &&
    typeof value["id"] === "string"
  ) {
    return true;
  }
  return false;
};

const userConverter: FirestoreDataConverter<User | null> = {
  toFirestore: (post: User) => {
    return post;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (isUser(data)) {
      return data;
    }
    console.error(`invalid data found: ${JSON.stringify(data)}`);
    return null;
  },
};

export async function checkUsers() {
  const usersRef = collection(firebaseFirestore, "users").withConverter(
    userConverter
  );
  const snapshot = await getDocs(usersRef);
  return snapshot.docs.flatMap((doc) => {
    const data = doc.data();
    return data ? data : [];
  });
}
