import { Button, Grid, Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "App";
import { ContributionCard } from "presentation/view_interfaces/components/contributionCard";
import {
  checkContribution,
  checkTask,
  Contribution,
  Task,
  Token,
} from "presentation/view_interfaces/FirebaseAction";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SectionTitle } from "../../ContributionsOfTeams";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useLocation, useNavigate } from "react-router-dom";

const TableWrapper = styled(Grid)`
  background: #121212;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.13),
    rgba(255, 255, 255, 0.13)
  );
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
  border-radius: 4px;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding: 16px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 20px;
  }
`;

type props = {
  teamid: string;
  taskid: string;
  token?: Token;
};

const pageSize = 5;

const TaskContributersTable = forwardRef(
  (
    { teamid, taskid, token }: props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const [contributions, setContribution] = useState<Contribution[]>([]);
    const [task, setTask] = useState<Task>();
    const setATfunc = useContext(AuthContext) as any;
    const isTaskOwner = useMemo(() => {
      const userId = setATfunc.tokenState?.id;
      const taskOwnerId = task?.createrid;
      if (userId && taskOwnerId) {
        return userId === taskOwnerId;
      }
      return false;
    }, [setATfunc.tokenState?.id, task?.createrid]);
    const navigate = useNavigate();
    const totalPageNum = useMemo(() => {
      return Math.ceil(contributions.length / pageSize);
    }, [contributions.length]);
    const [currentPageIdx, setCurrentPageIdx] = useState(0);

    const location = useLocation();

    const reloadContributions = useCallback(async () => {
      const resp = await checkContribution(teamid, taskid);
      setContribution(resp);
    }, [taskid, teamid]);

    useEffect(() => {
      reloadContributions();
    }, [reloadContributions, location.state]);

    useEffect(() => {
      (async () => {
        const resp = await checkTask(taskid);
        resp && setTask(resp);
      })();
    }, [taskid]);

    return (
      <TableWrapper ref={ref}>
        <Grid container>
          <Grid item md={11}>
            <SectionTitle variant="h5">{task?.content}</SectionTitle>
            <SectionTitle variant="h6">Related contributions</SectionTitle>
          </Grid>
          <Grid item md={1}>
            <Button onClick={() => navigate(`/tasks/${teamid}`)}>
              <KeyboardDoubleArrowRightIcon sx={{ color: "#fff" }} />
            </Button>
          </Grid>
        </Grid>
        {contributions.length > 0 ? (
          <Grid container direction="column" spacing={2}>
            {contributions
              .slice(
                pageSize * currentPageIdx,
                pageSize * currentPageIdx + pageSize
              )
              .map((contribution) => {
                return (
                  <Grid item key={contribution.id}>
                    <ContributionCard
                      minify={true}
                      maxWidth="none"
                      contribution={contribution}
                      canApproval={isTaskOwner}
                      userid={setATfunc.tokenState?.id}
                      reloadContributions={reloadContributions}
                      relatedTask={task}
                      token={token}
                    />
                  </Grid>
                );
              })}
            <Grid item container justifyContent="center">
              <Pagination
                count={totalPageNum}
                shape="rounded"
                onChange={(_, page) => {
                  setCurrentPageIdx(page - 1);
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <SectionTitle>まだContributionがありません</SectionTitle>
        )}
      </TableWrapper>
    );
  }
);

export default TaskContributersTable;
