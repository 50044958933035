import React, { useState, useEffect, useContext } from "react";
import { Box, Paper, Grid } from "@mui/material";
import {
  checkVoteCount,
  checkAdmin,
  checkProposalForProposal,
} from "./FirebaseAction";
import { ProposalCard } from "./components/proposalCard";
import { useParams } from "react-router-dom";
import Buttons from "./components/Buttons";
import { AuthContext } from "../../App";
import { styled } from "@mui/material/styles";
import ConvertUtils from "../utils/ConvertUtils";
import FilterSelect from "./components/FilterSelect";
import { useServerProfilesContext } from "./components/ServerProfileProvider";

export default function Proposals() {
  const { teamid = "" } = useParams();

  // userのidを取得、チームのadminであるか確認
  const setATfunc = useContext(AuthContext) as any;

  const [proposal, setProposal] = useState<any[]>([]);

  const [success, setSuccess] = React.useState("false");

  const [admin, setAdmin] = React.useState(false);
  const [userid, setUserid] = React.useState(null);
  const [agreeCounts, setAgreeCounts] = useState<number[]>([]);
  const [disagreeCounts, setDisagreeCounts] = useState<number[]>([]);

  // date params
  const [date, setDate] = React.useState<string | null>(
    ConvertUtils.generateSpecificYyyymm(0)
  );

  const { setTeam } = useServerProfilesContext();

  useEffect(() => {
    setTeam(teamid);
  }, [setTeam, teamid]);

  useEffect(() => {
    (async () => {
      const [agree, disagree] = await checkVoteCount(teamid);
      setAgreeCounts(agree);
      setDisagreeCounts(disagree);
      const proposals = await checkProposalForProposal(teamid, date);
      setProposal(proposals);
      if (setATfunc.tokenState !== null && teamid) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
    })();
  }, [setATfunc.tokenState, success, teamid, date]);

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      <Box display="flex" flexDirection="column">
        <FilterSelect date={date} setDate={setDate} />
        <Grid container spacing={2}>
          {proposal.map((eachProposal, index) => {
            return (
              <Grid item xs={12} md={4} key={index}>
                <ProposalCard
                  proposal={eachProposal}
                  setSuccess={setSuccess}
                  admin={admin}
                  userid={userid}
                  agree={agreeCounts[index]}
                  disagree={disagreeCounts[index]}
                ></ProposalCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
