interface FirebaseConfigType {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export const getFirebaseConfig = (env: string): FirebaseConfigType => {
  switch (env) {
    case "development":
      return {
        apiKey: "AIzaSyBkMZozVO_esXj3erdCWgE7oOqoSPKdqP8",
        authDomain: "dev-unyte.firebaseapp.com",
        projectId: "dev-unyte",
        storageBucket: "dev-unyte.appspot.com",
        messagingSenderId: "776740655680",
        appId: "1:776740655680:web:3401c86f86ee2a794445a5",
        measurementId: "G-CMCWGFJ14H",
      };
    case "production":
      return {
        apiKey: "AIzaSyBxeVKJ8OXUPiktwArnDZt_iA5XBMBGyYU",
        authDomain: "unyte-discordbot.firebaseapp.com",
        projectId: "unyte-discordbot",
        storageBucket: "unyte-discordbot.appspot.com",
        messagingSenderId: "971044855756",
        appId: "1:971044855756:web:5d2cc155660d9b82da4769",
        measurementId: "G-DD93D82RFP",
      };
    default:
      throw new Error("unexpected env");
  }
};

// const firebaseConfig2 = {
//   apiKey: "AIzaSyARB6spM1fKes144Nd2inq-shEOXPmhYzI",
//   authDomain: "wagumi-e39ac.firebaseapp.com",
//   projectId: "wagumi-e39ac",
//   storageBucket: "wagumi-e39ac.appspot.com",
//   messagingSenderId: "399605135152",
//   appId: "1:399605135152:web:dfc4515debd0ded6563af5",
//   measurementId: "G-KNPBXV7DH8"
// };
