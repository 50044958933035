const messages_ja = {
  "app.input.DAOs.label": "▼DAO一覧",
  "app.input.verify": "議論中",
  "app.input.verified": "承認済",
  "app.help.allocation":
    "活動が承認された後でもらえるトークンの量です。Admin権限のある方のみ変更できます",
  link: "リンク",
  admin: "▼このユーザーをAdminに設定しますか？",
  yes: "はい",
  no: "いいえ",
  delete: "この投稿を削除しますか？",
  proposal: "この提案を承認しますか？",
  contribution: "この貢献を承認しますか？",
  thx: "このthxを承認しますか？",
  view: "コンテンツを見る",
};

const messages_en = {
  "app.input.DAOs.label": "▼DAOs",
  "app.input.verify": "Verify",
  "app.input.verified": "Verified",
  "app.help.allocation":
    "Token amounts you can get when each activity is verified",
  link: "link",
  admin: "▼Do you want to make this user an Admin?",
  yes: "Yes",
  no: "No",
  delete: "Delete this post?",
  proposal: "Verify this Proposal?",
  contribution: "Verify this Contribution?",
  thx: "Verify this Thx?",
  view: "view content",
};

const messages = { ja: messages_ja, en: messages_en };
export default messages;
