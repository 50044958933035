import React, { useState } from "react";
import { Typography, Button, Box, IconButton, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DoubleArrowTwoToneIcon from "@mui/icons-material/DoubleArrowTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { approve, deleteThanks } from "../FirebaseAction";
import { Link } from "react-router-dom";
import LinkA from "@mui/material/Link";
import { FormattedMessage } from "react-intl";
import LaunchIcon from "@mui/icons-material/Launch";
import { useAvatarUrl } from "presentation/utils/useAvatar";
import { useUsername } from "presentation/utils/useUsername";

export const ThankCard = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const setMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setMenuClose = () => {
    setAnchorEl(null);
  };

  const [content, setContent] = useState({
    id: "id",
    name: "name",
    content: "content",
    from: {
      name: "name",
    },
    to: {
      name: "name",
    },
  });

  const ApproveDialogue = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="thx" />
          <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼message
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={() => {
              handleClose();
              console.log(content.id);
              approve("thanks", content.id).then((res) => {
                props.setSuccess(res);
                console.log(res);
              });
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function autoLink(str) {
    let result = false;
    if (str.includes("http")) {
      result = true;
    }
    const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    const eliminateNum = /<@![0-9]+>/;
    if (str.length >= 120) {
      return [
        result,
        str.match(regexp_url),
        str.slice(0, 120).replace(regexp_url, "").replace(eliminateNum, "") +
          "...",
      ];
    } else {
      return [
        result,
        str.match(regexp_url),
        str.replace(regexp_url, "").replace(eliminateNum, ""),
      ];
    }
  }

  const DeleteDialogue = () => {
    return (
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="delete" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ▼Content
            <br></br>
            {content.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={async () => {
              setDeleteOpen(false);
              const result = await deleteThanks(props.userid, props.thank.id);
              props.setSuccess(result);
            }}
            autoFocus
          >
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const senderAvatarUrl = useAvatarUrl(props.thank.from.id);
  const recieverAvatarUrl = useAvatarUrl(props.thank.to.id);
  const senderUsername = useUsername(props.thank.from.id);
  const recieverUsername = useUsername(props.thank.to.id);

  return (
    <div>
      <ApproveDialogue></ApproveDialogue>
      <DeleteDialogue></DeleteDialogue>
      <Card sx={{ maxWidth: 600, height: 240 }}>
        <Grid container alignItems="center">
          <Grid item xs={5} md={5}>
            <CardHeader
              avatar={<Avatar src={senderAvatarUrl}></Avatar>}
              title={senderUsername}
            ></CardHeader>
          </Grid>
          <Grid item xs={1} md={2}>
            <DoubleArrowTwoToneIcon />
          </Grid>
          <Grid item xs={6} md={5} alignItems="left">
            <CardHeader
              avatar={<Avatar src={recieverAvatarUrl}></Avatar>}
              title={recieverUsername}
              action={
                <div>
                  <IconButton aria-label="settings" onClick={setMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={setMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={setMenuClose}
                      component={Link}
                      to={`/profile/${props.thank.to.id}`}
                    >
                      View Profile
                    </MenuItem>
                    {props.thank.channelid !== undefined ? (
                      <LinkA
                        href={`https://discord.com/channels/${props.thank.teamid}/${props.thank.channelid}/${props.thank.id}`}
                        underline="none"
                        target="_blank"
                      >
                        <MenuItem onClick={setMenuClose}>View Content</MenuItem>
                      </LinkA>
                    ) : (
                      <Box></Box>
                    )}
                    {props.userid === props.thank.from.id && (
                      <MenuItem
                        onClick={async () => {
                          setMenuClose();
                          setContent(props.thank);
                          setDeleteOpen(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              }
            ></CardHeader>
          </Grid>
        </Grid>
        <CardActions>
          {props.thank.approved === "approved" ? (
            <Button
              sx={{
                textTransform: "none",
                maxWidth: "120px",
                minWidth: "120px",
                color: "#00ff7f",
              }}
            >
              <DoneIcon></DoneIcon>
              <Typography>Verified</Typography>
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                maxWidth: "64px",
                minWidth: "120px",
                color: "#a9a9a9",
              }}
              onClick={() => {
                if (props.admin) {
                  setContent(props.thank);
                  handleClickOpen();
                } else {
                  setContent(props.thank);
                  // test用。解除しておく
                  // handleClickOpen();
                }
              }}
            >
              <Typography>Verify</Typography>
            </Button>
          )}
          <div style={{ flexGrow: 1 }}></div>
          {props.thank.twitter !== undefined ? (
            <Button
              sx={{ marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                window.open(
                  `https://twitter.com/${props.thank.user.username}/status/${props.thank.id}`,
                  "_blank"
                );
              }}
            >
              <Typography sx={{ color: "Gray" }}>View on Twitter</Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          ) : props.thank.channelid !== undefined ? (
            <Button
              sx={{ marginLeft: "auto", color: "Gray" }}
              onClick={() => {
                window.open(
                  `https://discord.com/channels/${props.thank.teamid}/${props.thank.channelid}/${props.thank.id}`,
                  "_blank"
                );
              }}
            >
              <Typography sx={{ color: "Gray" }}>View on Discord</Typography>
              <LaunchIcon></LaunchIcon>
            </Button>
          ) : (
            <Box></Box>
          )}
        </CardActions>
        <CardContent sx={{ textAlign: "center" }}>
          {autoLink(props.thank.content)[0] ? (
            <Typography>
              {autoLink(props.thank.content)[2]}
              <LinkA
                href={autoLink(props.thank.content)[1]}
                underline="none"
                target="_blank"
              >
                (<FormattedMessage id="link" />)
              </LinkA>
            </Typography>
          ) : (
            <Typography>{autoLink(props.thank.content)[2]}</Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
