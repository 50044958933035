import React, { useState, useEffect, useContext } from "react";
import { Box, Paper, Grid } from "@mui/material";
import { newCheckThank, checkAdmin } from "./FirebaseAction";
import { useParams } from "react-router-dom";
import Buttons from "./components/Buttons";
import "./styles/App.css";
import { ThankCard } from "./components/thankCard";
import { AuthContext } from "../../App";
import FilterSelect from "./components/FilterSelect";
import ConvertUtils from "../utils/ConvertUtils";
import { useServerProfilesContext } from "./components/ServerProfileProvider";

export default function Thanks() {
  const { teamid = "" } = useParams();
  // userのidを取得、チームのadminであるか確認
  const setATfunc = useContext(AuthContext) as any;
  const [thank, setThank] = useState<any[]>([]);
  const [success, setSuccess] = React.useState("false");
  const [admin, setAdmin] = React.useState(false);
  const [userid, setUserid] = React.useState(null);
  // date params
  const [date, setDate] = useState<string>(
    ConvertUtils.generateSpecificYyyymm(0)
  );

  const { setTeam } = useServerProfilesContext();

  useEffect(() => {
    setTeam(teamid);
  }, [setTeam, teamid]);

  useEffect(() => {
    (async () => {
      if (teamid) {
        const thanks = await newCheckThank(teamid, date);
        setThank(thanks);
        if (setATfunc.tokenState !== null) {
          // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
          const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
          setAdmin(adminBool);
          setUserid(setATfunc.tokenState.id);
        }
      }
    })();
  }, [setATfunc.tokenState, success, teamid, date]);

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      <Box display="flex" flexDirection="column">
        <FilterSelect date={date} setDate={setDate} />
        <Grid container spacing={2}>
          {thank.map((eachThank, index) => {
            return (
              <Grid item xs={12} md={4}>
                <ThankCard
                  thank={eachThank}
                  setSuccess={setSuccess}
                  admin={admin}
                  userid={userid}
                ></ThankCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
