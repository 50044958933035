import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import {
  checkContribution,
  checkContributionForList,
  checkAdmin,
  checkTeam,
} from "./FirebaseAction";
import { useParams } from "react-router-dom";
import Buttons from "./components/Buttons";
import { ContributionCard } from "./components/contributionCard";
import { AuthContext } from "../../App";
import { styled } from "@mui/material/styles";
import FilterSelect from "./components/FilterSelect";
import ConvertUtils from "../utils/ConvertUtils";
import { useServerProfilesContext } from "./components/ServerProfileProvider";

export const SectionTitle = styled(Typography)`
  color: white;
`;

export default function Contributions() {
  const { teamid = "" } = useParams();

  // 描画用
  const [success, setSuccess] = React.useState("false");

  const [contribution, setContribution] = React.useState<any[]>([]);
  const [userid, setUserid] = React.useState<string | null>(null);
  const setATfunc = useContext(AuthContext) as any;
  const [admin, setAdmin] = React.useState(false);
  const [tags, setTags] = React.useState<any[]>([]);

  // date params
  const [date, setDate] = React.useState<string | null>(
    ConvertUtils.generateSpecificYyyymm(0)
  );

  const { setTeam } = useServerProfilesContext();

  useEffect(() => {
    setTeam(teamid);
  }, [setTeam, teamid]);

  useEffect(() => {
    (async () => {
      const contributions = await checkContributionForList(teamid, date);
      await checkTeam(teamid).then(async function (result) {
        if (result && result.commands !== undefined) {
          const arr = [] as any[];
          result.commands.forEach(async (command) => {
            console.log(command.command);
            arr.push(command.command);
          });
          setTags(arr);
          return arr;
        } else {
          return [];
        }
      });
      setContribution(contributions);
      if (setATfunc.tokenState !== null && teamid) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        const adminBool = await checkAdmin(teamid, setATfunc.tokenState.id);
        setAdmin(adminBool);
        setUserid(setATfunc.tokenState.id);
      }
    })();
  }, [setATfunc.tokenState, success, teamid, date]);

  const [isCompleted, setIsCompleted] = useState(true);
  const [isWIP, setIsWIP] = useState(true);

  const SideBar = styled(Grid)``;
  const PageWrapper = styled(Grid)`
    width: 100vw;
    ${(props) => props.theme.breakpoints.up("xs")} {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 24px;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-top: 40px;
    }
  `;

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Buttons guildId={teamid}></Buttons>
      </Paper>
      <Box display="flex" flexDirection="column">
        <FilterSelect date={date} setDate={setDate} />
        <Box display="flex" justifyContent="center">
          <PageWrapper container spacing={0}>
            <SideBar xs={12} md={2}>
              <FormGroup>
                <SectionTitle variant="h5" gutterBottom>
                  CATEGORY
                </SectionTitle>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      checked={isCompleted}
                      onChange={() => setIsCompleted(!isCompleted)}
                    />
                  }
                  label="ALL"
                  sx={{ color: "#66bb6a" }}
                />
                {tags.length !== 0 &&
                  tags.map((tag, i) => {
                    // console.log(tag)
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={isWIP}
                            onChange={() => setIsWIP(!isWIP)}
                          />
                        }
                        label={tag}
                        sx={{ color: "#ff7f50" }}
                      />
                    );
                  })}
              </FormGroup>
            </SideBar>
            <Grid container spacing={2} md={10}>
              {contribution.map((eachContribution, index) => {
                return (
                  <Grid item xs={12} md={4}>
                    <ContributionCard
                      tags={tags}
                      contribution={eachContribution}
                      setSuccess={setSuccess}
                      canApproval={admin}
                      userid={userid}
                    ></ContributionCard>
                  </Grid>
                );
              })}
            </Grid>
          </PageWrapper>
        </Box>
      </Box>
    </div>
  );
}
