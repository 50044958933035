import React, { useState, useEffect, useContext, useCallback } from "react";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import {
  countActivity,
  checkMyProposal,
  checkMyContribution,
  checkMyThanks,
  checkMyThanksGave,
  searchUser,
  checkAllToken,
  checkUsersToken,
  AllToken,
} from "./FirebaseAction";
import { useParams } from "react-router-dom";
import { ProposalCard } from "./components/proposalCard";
import { ContributionCard } from "./components/contributionCard";
import { ThankCard } from "./components/thankCard";

import { AuthContext } from "../../App";

function createData(name: string, address: string) {
  return { name, address };
}

const Profile = (props) => {
  const { id = "" } = useParams();
  const [iconImg, setIconImg] = useState("");
  // 各アクティビティの実績数を取得する状態変数
  const [activity, setActivity] = React.useState({
    proposals: 0,
    tasks: 0,
    comments: 0,
    commentsgave: 0,
  });
  const [tags] = React.useState<any[]>([]);

  const connect = useCallback(async () => {
    searchUser(id).then(function (result) {
      if (result === "anonymous") {
        readAccount("anonymous", "anonymous");
      } else {
        // console.log(result)
        if (result.twitter !== undefined) {
          readAccount(result.name, result.username);
          setIconImg(result.avatar);
        } else {
          readAccount(result.username, "#" + result.discriminator);
          setIconImg(
            `https://cdn.discordapp.com/avatars/${result.id}/${result.avatar}`
          );
        }
      }
    });
    const acti = await countActivity(id);
    setActivity({
      proposals: acti[0],
      tasks: acti[1],
      comments: acti[2],
      commentsgave: acti[3],
    });
  }, [id]);

  const [usersTokenArr, setUsersTokenArr] = React.useState<AllToken[]>([]);
  const [, setTokenContentArr] = React.useState([
    [
      {
        avatar:
          "https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg",
        id: "UNYT",
        name: "UNYT",
      },
    ],
  ]);
  useEffect(() => {
    connect();
    (async () => {
      const result = await checkAllToken(id);
      setUsersTokenArr(result);
      const token = await checkUsersToken(result);
      setTokenContentArr(token);
    })();
  }, [connect, id]);

  const [rows, setRows] = React.useState<any[]>([]);

  async function readAccount(name, address) {
    const arr: any = [];
    // アドレスがDBにあるか探索
    await arr.push(createData(name, address));
    await setRows(arr);
  }

  const setATfunc = useContext(AuthContext) as any;
  const [success, setSuccess] = React.useState("false");
  // ログイン中のユーザーの情報取得
  const [userid, setUserid] = React.useState(null);

  useEffect(() => {
    (async () => {
      if (setATfunc.tokenState !== null) {
        // ログイン中の場合はユーザーIDを取得、adminかどうかをチェック
        setUserid(setATfunc.tokenState.id);
      }
    })();
  }, [setATfunc.tokenState, success]);

  function MyProposalTable() {
    const [myProposals, setMyProposals] = React.useState<any[]>([]);
    useEffect(() => {
      (async () => {
        const myProposalsArr = await checkMyProposal(id);
        setMyProposals(myProposalsArr);
      })();
    }, []);
    return (
      <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {myProposals.map((proposal, index) => {
            return (
              <Grid item xs={12} md={4}>
                <ProposalCard
                  proposal={proposal}
                  setSuccess={setSuccess}
                  userid={userid}
                ></ProposalCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  function MyTaskTable() {
    const [taskid, setMyTaskId] = React.useState<any[]>([]);
    useEffect(() => {
      (async () => {
        const myContributionsArr = await checkMyContribution(id);
        setMyTaskId(myContributionsArr);
      })();
    }, []);
    return (
      <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {taskid.map((contributions, index) => {
            return (
              <Grid item xs={12} md={4}>
                {/* contributions */}
                <ContributionCard
                  tags={tags}
                  contribution={contributions}
                  setSuccess={setSuccess}
                  userid={userid}
                ></ContributionCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  function MyThankTable() {
    const [myThanks, setMyThanks] = React.useState<any[]>([]);
    useEffect(() => {
      (async () => {
        const myThanksArr = await checkMyThanks(id);
        setMyThanks(myThanksArr);
      })();
    }, []);
    return (
      <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {myThanks.map((thanks, index) => {
            return (
              <Grid item xs={12} md={4}>
                <ThankCard
                  thank={thanks}
                  setSuccess={setSuccess}
                  userid={userid}
                ></ThankCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  function MyThankTableGave() {
    const [myThanks, setMyThanks] = React.useState<any[]>([]);
    useEffect(() => {
      (async () => {
        const myThanksArr = await checkMyThanksGave(id);
        setMyThanks(myThanksArr);
      })();
    }, []);
    return (
      <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {myThanks.map((thanks, index) => {
            return (
              <Grid item xs={12} md={4}>
                <ThankCard
                  thank={thanks}
                  setSuccess={setSuccess}
                  userid={userid}
                ></ThankCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  const [selected, setSelected] = React.useState("contribution");
  const CardsForSp = (props) => {
    return (
      <div>
        <Grid
          container
          direction="row"
          spacing={0}
          alignItems={"center"}
          className="loading-container"
        >
          <Grid item xs={6} sm={3}>
            <Button
              variant={selected === "contribution" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "contribution"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("contribution");
              }}
            >
              Contributions
              <br />({activity.tasks})
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              variant={selected === "proposal" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "proposal"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("proposal");
              }}
            >
              Proposals
              <br />({activity.proposals})
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              variant={selected === "thanksforyou" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "thanksforyou"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("thanksforyou");
              }}
            >
              Thanks-For
              <br />({activity.comments})
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              variant={selected === "thanksfromyou" ? "text" : "outlined"}
              sx={{
                width: "150px",
                background:
                  selected === "thanksfromyou"
                    ? "linear-gradient(45deg, #ff7f50,#ff1493)"
                    : "",
                color: "white",
                mt: 2,
              }}
              onClick={() => {
                setSelected("thanksfromyou");
              }}
            >
              Thanks-From
              <br />({activity.commentsgave})
            </Button>
          </Grid>
        </Grid>
        {selected === "contribution" && <MyTaskTable key={id} />}
        {selected === "proposal" && <MyProposalTable key={id} />}
        {selected === "thanksforyou" && <MyThankTable key={id} />}
        {selected === "thanksfromyou" && <MyThankTableGave key={id} />}
      </div>
    );
  };

  return (
    <div className="">
      <Box>
        <Box sx={{ display: { xs: "flex", sm: "flex" } }}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                {rows.map((row, i) => {
                  return (
                    <Grid item xs={12}>
                      <Box
                        sx={{ mt: 2, mb: 2 }}
                        display="flex"
                        justifyContent="center"
                      >
                        <Card sx={{ maxWidth: 600 }}>
                          <CardHeader
                            avatar={<Avatar src={iconImg}></Avatar>}
                            action={
                              <IconButton aria-label="settings">
                                <MoreVertIcon />
                              </IconButton>
                            }
                            title={
                              <Typography align="center">{row.name}</Typography>
                            }
                            // subheader="September 14, 2016"
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              {usersTokenArr.map((token, i) => {
                                console.log(token);
                                return (
                                  <Grid item xs={12} md={6}>
                                    <Button sx={{ color: "white", m: 1 }}>
                                      <Avatar src={token.token}></Avatar>
                                      <Typography sx={{ ml: 3, mr: 3 }}>
                                        {token.balance}
                                        {token.team.token}
                                      </Typography>
                                    </Button>
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Typography sx={{ mt: 1.5 }} color="text.secondary">
                              {/* {row.address} */}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider></Divider>
      {/* < Box sx={{ display: { xs: "none", sm: "flex" } }}> */}
      {/* <CenteredTabs sx={{ display: { xs: "none", sm: "flex" } }} labels={[`Contributions(${activity.tasks})`,`Proposal(${activity.proposals})`,`thanksfor(${activity.comments})`,`thanksfrom(${activity.commentsgave})`]}>
        <div>
          <MyTaskTable id={id} />
        </div>
        <div>
          <MyProposalTable id={id} />
        </div>
        <div>
          <MyThankTable id={id} />
        </div>
        <div>
          <MyThankTableGave id={id} />
        </div>
      </CenteredTabs >
      <TeamComments id={id} byoga={byoga} /> */}
      {/* </Box> */}
      {/* < Box sx={{ display: { xs: "flex", sm: "none" } }}> */}
      <Box>
        <CardsForSp></CardsForSp>
      </Box>
    </div>
  );
};

export default Profile;
