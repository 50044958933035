import { useServerProfilesContext } from "presentation/view_interfaces/components/ServerProfileProvider";
import { useMemo } from "react";

/**
 * discrodのユーザー名を取得する。サーバーのnicknameがある場合は優先してそのnicknameを返却する。
 * https://discord.com/developers/docs/reference#image-formatting-cdn-endpoints に準拠している
 * @param userid ユーザー名を取得したいユーザーのid
 * @returns ユーザー名
 */
export const useUsername = (userid: string) => {
  const { profileList, globalProfileList } = useServerProfilesContext();
  const username = useMemo(() => {
    const serverProfile = profileList.find(
      (profile) => profile.userid === userid
    );

    if (serverProfile?.username) {
      return serverProfile.username;
    }

    const globalProfile = globalProfileList.find(
      (profile) => profile.userid === userid
    );
    if (globalProfile) {
      return globalProfile.username;
    }
  }, [globalProfileList, profileList, userid]);

  return username;
};
