import React, { useEffect, useContext, useCallback } from "react";
import { AuthContext } from "../../App";
import { Typography, Box, Paper, Grid } from "@mui/material";
import { setUser, fetchTeams } from "./FirebaseAction";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { FormattedMessage } from "react-intl";

// リンクへのアクセス
import { Link } from "react-router-dom";

export default function Top() {
  const setATfunc = useContext(AuthContext) as any;

  // team一覧を保存するための状態変数
  const [teams, setTeams] = React.useState<any[]>([
    {
      name: "UnyteDAO",
      description: "test",
      avatar:
        "https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg",
      id: "945194711973498920",
    },
  ]);

  // TODO: apiの呼び出しはファイルで切り出す
  const fetchUrl = useCallback(async () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];
    console.log(accessToken, tokenType);
    if (accessToken !== null) {
      console.log(accessToken);
      console.log(tokenType);
      fetch("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      })
        .then((result) => result.json())
        .then((response) => {
          setATfunc.setATCallback(response); // Context値更新
          setUser(response);
        })
        .catch(console.error);
    }
  }, [setATfunc]);

  useEffect(() => {
    fetchUrl();
    (async () => {
      const teamArr = await fetchTeams();
      setTeams(teamArr);
      console.log(teamArr);
    })();
  }, []);

  useEffect(() => {
    console.log(setATfunc.tokenState);
  }, [setATfunc]);

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: 3, mb: 3 }}>
        <Typography
          sx={{ flex: "1 1 100%" }}
          align="center"
          variant="h4"
          id="tableTitle"
          component="div"
        >
          <FormattedMessage id="app.input.DAOs.label" />
        </Typography>
      </Paper>
      <Box display="flex" justifyContent="center">
        <Grid container spacing={2}>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/972122010786357248"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/972122010786357248/330e92e96a811952c6b7dade36e6ead9.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  VeryLongAnimals
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  顔が長いやつらの集まり(短くても歓迎)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/973226564768649316"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://pbs.twimg.com/profile_images/1568231596238737410/ZWFf8fCr_400x400.jpg`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  MedicalDAO
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  web3でミライの医療を考えるコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/1036863884528734260"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://pbs.twimg.com/media/FhwvcdYaUAAbeZN.jpg`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  PharamacistDAO
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  「薬剤師×web3」を実現するコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/988660183364423710"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/988660183364423710/d91b14d92a90972723d6c0f054f2e548.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Roopt DAO
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  DAO型シェアハウス：入居者・支援者が自律分散的に運営中
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/933354919820419072"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/933354919820419072/46d6107397c2e3ad04d96d0bf365f470.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  DAOヶ島
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  青ヶ島好きのためのコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/951780348465909820"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/951780348465909820/8b37122d008179e9717398a25346cfc9.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Metagri研究所
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  農業×ブロックチェーンの事業モデルを実験するコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/1012620862588997705"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/1012620862588997705/9d83bde97bc9928c11763c34b86e7a70.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  SUSHI DAO
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Spatians Japanユーザーコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/1055644755519873104"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/1055644755519873104/b7bbd17faceb79def06b0ea03260a997.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  医療人2030 SPACE THREE
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Web3/NFTとメタバースを活用した医療インタープレナーDAO
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/1012359990033526804"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://cdn.discordapp.com/icons/1012359990033526804/7906a0b7845cf7592766a283c29354be.webp?size=240`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Symbol/NEM Marketing
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  人々が Symbol/NEM を使う意義を作るためのコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/1068105624103170079"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Unyte DAO - Founders Forum
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  UnyteのFoundersコミュニティ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ textDecoration: "none" }}
            item
            xs={12}
            md={4}
            component={Link}
            to="/proposals/945194711973498920"
          >
            <Card
              sx={{
                minWidth: 145,
                pl: 2,
                Pr: 2,
                ml: 2,
                mr: 2,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                    src={`https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg`}
                  ></Avatar>
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Unyte DAO
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Unyteの開発に取り組むDAO
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {teams.map((eachTeam, index) => {
            return (
              !eachTeam.hide && (
                <Grid
                  key={eachTeam.id}
                  style={{ textDecoration: "none" }}
                  item
                  xs={12}
                  md={4}
                  component={Link}
                  to={`/proposals/${eachTeam.id}`}
                >
                  <Card
                    sx={{
                      minWidth: 145,
                      pl: 2,
                      Pr: 2,
                      ml: 2,
                      mr: 2,
                      textAlign: "center",
                    }}
                  >
                    <CardContent>
                      <Box display="flex" justifyContent="center">
                        <Avatar
                          sx={{ mb: 2, mt: 2, width: 56, height: 56 }}
                          src={eachTeam.avatar}
                        ></Avatar>
                      </Box>
                      <Typography gutterBottom variant="h5" component="div">
                        {eachTeam.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {eachTeam.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
